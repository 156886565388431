import dayjs from 'dayjs';
import { useWorkPeriodsMeasureMultiple } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  Measure,
  MeasureDataResponse,
  Transformer,
  UseWorkPeriodsMeasurePayloadMultiple,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  useDateRange,
  useEntities,
  useTimeAllocation,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { useMeasureFilter } from '../comparison-view/comparison-view.hooks';
import { getEntityType } from '../process-analysis.helpers';

/**
 * Custom hook that fetches measure comparison data.
 *
 * @returns {{ data: MeasureDataResponse | null; isFetching: boolean }} - An object containing the fetched data and an isFetching flag.
 */
const useMeasureComparisonData = (): { data: MeasureDataResponse | null; isFetching: boolean } => {
  const { defaultMeasures, transformerMeasures } = useMeasureFilter();
  const entities = useEntities();
  const entity = entities[0];

  const defaultPayload = usePayload(defaultMeasures);
  const { data: defaultData, isFetching: isFetchingDefaultData } = useWorkPeriodsMeasureMultiple(defaultPayload);
  const parsedDefaultData = defaultData && entity ? defaultData[entity] : null;

  const transformerPayload = usePayload(transformerMeasures);
  const { data: transformerData, isFetching: isFetchingTransformerData } = useWorkPeriodsMeasureMultiple({
    ...transformerPayload,
    transformer: Transformer.TimeAllocationValuesOnly,
  });
  const parsedTransformerData = transformerData && entity ? transformerData[entity] : null;

  const data = { ...parsedDefaultData, ...parsedTransformerData } as MeasureDataResponse;
  const isFetching = isFetchingDefaultData || isFetchingTransformerData;

  return { data, isFetching };
};

/**
 * Custom hook that returns a payload for fetching measure comparison data.
 *
 * @param {Measure} measures - The list of measures for the payload
 * @return {UseWorkPeriodsMeasurePayloadMultiple} - The payload for fetching work period measures.
 */
const usePayload = (measures: Measure[]): UseWorkPeriodsMeasurePayloadMultiple => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();
  const timeAllocation = useTimeAllocation();

  return {
    entity: getEntityType(activeTab),
    ids: entities,
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    measure_name: measures,
    time_allocation_type: timeAllocation,
  };
};

export { useMeasureComparisonData };
