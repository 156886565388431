import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { useEffect } from 'react';
import { useGlobalStore } from '../../store/global-store/global-store';

export function TeamMultiselect(props: Omit<MultiSelectProps, 'data'>) {
  const { value: teamIds, onChange: handleTeamSelected, ...rest } = props;
  const teams = useGlobalStore((state) => state.teams);

  useEffect(() => {
    if (teams?.length && !teamIds?.length) {
      handleTeamSelected && handleTeamSelected([teams[0].id]); // updates dropdown value
    }
  }, [teams, teamIds, handleTeamSelected]);

  function onTeamSelected(teamIds: string[]) {
    handleTeamSelected && handleTeamSelected(teamIds);
  }

  return (
    <MultiSelect
      {...rest}
      value={teams.length ? teamIds : []}
      data={(teams || []).map((t) => ({ value: t.id, label: t.name }))}
      onChange={onTeamSelected}
      style={{ minWidth: 200 }}
    />
  );
}
