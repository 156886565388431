import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSpending } from '../../api/financials-client/financials-client';
import { Spending } from '../../api/financials-client/financials-client.type';
import { Team } from '../../api/projects-client/projects-client.type';
import { fetchPortfolioProjects } from '../../api/summary-client/summary-client';
import { AddFinancialData } from '../../components/add-financial-data/add-financial-data';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb';
import { FinancialsCharts } from '../../components/financials-charts/financials-charts';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { PortfolioContext, ProjectContext, SidebarContext, TeamContext } from '../../contexts';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { getFinancialsEndDate, getFinancialsStartDate } from '../../helpers/storage/storage';
import { newCOLORS } from '../../styles/colors';
import { BreadcrumbContainer } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { onPortfolioBreadcrumbClick } from '../side-bar/side-bar.helper';
import { getMessageText, getYearsBetween } from './finanacials.helper';

export const Financials = () => {
  useDocumentTitle('Financials - Bloomfilter');
  const navigate = useNavigate();
  const { portfolio } = useContext(PortfolioContext);
  const { setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);
  const { team } = useContext(TeamContext);
  const [nullExperience, setNullExperience] = useState(true);
  const [messageText, setMessageText] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(getFinancialsStartDate());
  const [endDate, setEndDate] = useState<Date | null>(getFinancialsEndDate());

  const getSpendingQuery = useQuery(
    ['getSpending', portfolio?.id],
    () => (portfolio?.id ? getSpending(portfolio?.id, getYearsBetween()) : Promise.reject('Missing Portfolio ID')),
    {
      enabled: !!portfolio?.id,
    }
  );

  const fetchPortfolioProjectsQuery = useQuery(
    ['fetchPortfolioProjects', portfolio?.id],
    () => portfolio && fetchPortfolioProjects(portfolio?.id),
    {
      enabled: !!portfolio?.id,
    }
  );

  useEffect(() => {
    // Rules:
    //  Portfolio Has Spending
    //      Aggregate Selected - Show Chart
    //      Team Selected
    //          Team Has Spending - Show Chart
    //          Team Has No Spending - Navigate to Edit Financials
    //  Portfolio Has No Spending - Show Add Financials (that will subsequently navigate to Edit Financials)

    // We have any spending data for the portfolio
    if (getSpendingQuery.data && getSpendingQuery.data.length > 0) {
      // A team was selected and we have no spending data for that team
      if (team && team?.id !== 'aggregate' && !getSpendingQuery.data.some((item) => item.project_id === team?.id)) {
        // navigate to the upsert spending page
        navigate(`/application/financials/portfolio/${portfolio?.id}/add-edit-financials`, {
          state: { from: 'application' },
        });
      } else {
        // A team was selected and we have spending data
        // show the charts
        setNullExperience(false);
      }
    } else {
      // we dont have spending data back yet or we have no spending data for the portfolio
      setNullExperience(true);
    }
  }, [getSpendingQuery.data, team, setNullExperience, navigate, portfolio]);

  // Below is the update the message in the header based on the data we have
  useEffect(() => {
    setMessageText(
      getMessageText(team as Team, getSpendingQuery.data as Spending[], fetchPortfolioProjectsQuery.data?.length || 0)
    );
  }, [team, getSpendingQuery.data, fetchPortfolioProjectsQuery.data, startDate, endDate]);

  return (
    <Fragment>
      <MenuDrivenContainer
        contentStyles={{ backgroundColor: newCOLORS.lighterGray }}
        containerStyles={{ backgroundColor: newCOLORS.lighterGray }}
      >
        <BrandedLoadingOverlay visible={getSpendingQuery.isLoading} transitionDuration={30} variant="colored" />
        <div style={{ padding: '15px' }}>
          {portfolio?.organization && (
            <BreadcrumbContainer style={{ paddingBottom: 0, height: 40 }}>
              <Breadcrumb
                crumbItems={[
                  {
                    labelName: portfolio.organization?.name || 'Portfolio',
                    href: '/application/dashboard',
                    onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
                  },
                  { labelName: 'Financials' },
                ]}
              />
            </BreadcrumbContainer>
          )}
          {messageText ? <PageHeaderMessage message={messageText} color="yellow"></PageHeaderMessage> : null}
          <FinancialsContainer>
            {nullExperience ? (
              <AddFinancialData />
            ) : (
              <FinancialsCharts
                spendingQuery={getSpendingQuery}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            )}
          </FinancialsContainer>
        </div>
      </MenuDrivenContainer>
    </Fragment>
  );
};

const FinancialsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
