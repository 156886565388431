import dayjs from 'dayjs';
import { useWorkPeriodsMeasureMultiple } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  Measure,
  MeasureDataByEntity,
  TimeAllocation,
  Transformer,
  UseWorkPeriodsMeasurePayloadMultiple,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  useAvailableMeasureNames,
  useDateRange,
  useEntities,
  useTimeAllocation,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { getEntityType } from '../process-analysis.helpers';

const timeAllocationTransformerMeasures = [
  Measure.Complexity,
  Measure.DeclinedChangeRequests,
  Measure.Independence,
  Measure.Quality,
  Measure.ReviewTime,
  Measure.ScopeCreep,
  Measure.Strategy,
];

/**
 * Custom hook that fetches the monthly averages data.
 *
 * @returns {{ data: MeasureDataByEntity | undefined; isFetching: boolean }} - An object containing the fetched data and an isFetching flag.
 */
const useMonthlyAveragesData = (): { data: MeasureDataByEntity | undefined; isFetching: boolean } => {
  const { defaultMeasures, transformerMeasures } = useMeasureFilter();

  const defaultPayload = usePayload(defaultMeasures, TimeAllocation.Monthly);
  const { data: defaultData, isFetching: isFetchingDefaultData } = useWorkPeriodsMeasureMultiple(defaultPayload);

  const transformerPayload = usePayload(transformerMeasures, TimeAllocation.Monthly);
  const { data: transformerData, isFetching: isFetchingTransformerData } = useWorkPeriodsMeasureMultiple({
    ...transformerPayload,
    transformer: Transformer.TimeAllocationValuesOnly,
  });

  const data = useAggregatedData(defaultData, transformerData);
  const isFetching = isFetchingDefaultData || isFetchingTransformerData;

  return { data, isFetching };
};

/**
 * Custom hook that fetches the key measures over time data.
 *
 * @returns {{ data: MeasureDataByEntity | undefined; isFetching: boolean }} - An object containing the fetched data and an isFetching flag.
 */
const useKeyMeasuresOverTimeData = (): { data: MeasureDataByEntity | undefined; isFetching: boolean } => {
  const { defaultMeasures, transformerMeasures } = useMeasureFilter();
  const timeAllocation = useTimeAllocation();

  const defaultPayload = usePayload(defaultMeasures, timeAllocation);
  const { data: defaultData, isFetching: isFetchingDefaultData } = useWorkPeriodsMeasureMultiple(defaultPayload);

  const transformerPayload = usePayload(transformerMeasures, timeAllocation);
  const { data: transformerData, isFetching: isFetchingTransformerData } = useWorkPeriodsMeasureMultiple({
    ...transformerPayload,
    transformer: Transformer.TimeAllocationValuesOnly,
  });

  const data = useAggregatedData(defaultData, transformerData);
  const isFetching = isFetchingDefaultData || isFetchingTransformerData;

  return { data, isFetching };
};

/**
 * Custom hook that returns a payload for fetching work period measures.
 *
 * @param {TimeAllocation} timeAllocation - The time allocation for the payload.
 * @return {UseWorkPeriodsMeasurePayloadMultiple} - The payload for fetching work period measures.
 */
const usePayload = (measures: Measure[], timeAllocation: TimeAllocation): UseWorkPeriodsMeasurePayloadMultiple => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();

  return {
    entity: getEntityType(activeTab),
    ids: entities,
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    measure_name: measures,
    time_allocation_type: timeAllocation,
  };
};

/**
 * Custom hook that filters measures into two categories:
 *   - `defaultMeasures`: measures that don't have a transformer
 *   - `transformerMeasures`: measures that have a transformer
 *
 * @returns {{ defaultMeasures: Measure[]; transformerMeasures: Measure[] }} - An object containing both types of measures.
 */
const useMeasureFilter = (): { defaultMeasures: Measure[]; transformerMeasures: Measure[] } => {
  const measures = useAvailableMeasureNames();

  return measures.reduce(
    (acc, measure) => {
      const isTransformerMeasure = timeAllocationTransformerMeasures.includes(measure);

      if (isTransformerMeasure) {
        return { ...acc, transformerMeasures: [...acc.transformerMeasures, measure] };
      }

      return { ...acc, defaultMeasures: [...acc.defaultMeasures, measure] };
    },
    { defaultMeasures: [] as Measure[], transformerMeasures: [] as Measure[] }
  );
};

/**
 * Custom hook that aggregates two measure data objects into one.
 * Used to combine default measures and measures with a transformer.
 *
 * @param defaultData - The default measures data.
 * @param transformerData - The measures with a transformer data.
 * @returns {MeasureDataByEntity} - A single measure data object with all measures from both inputs.
 */
const useAggregatedData = (
  defaultData: MeasureDataByEntity | undefined,
  transformerData: MeasureDataByEntity | undefined
): MeasureDataByEntity => {
  const entities = useEntities();

  const data = entities.reduce((acc, id) => {
    return {
      ...acc,
      [id]: {
        ...defaultData?.[id],
        ...transformerData?.[id],
      },
    };
  }, {});

  return data;
};

export { useKeyMeasuresOverTimeData, useMeasureFilter, useMonthlyAveragesData };
