import { AxiosError, AxiosResponse } from 'axios';
import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { ProcessAnalysisMeasureComparisonData } from '../board-client/board-client.type';
import {
  EpicsPaceTracker,
  Portfolio,
  PortfolioMetrics,
  PortfolioPayload,
  ProcessAnalysisChartData,
  ProjectTimelines,
} from './portfolio-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/api/portfolios/';

/**
 * Method to create a new portoflio
 * @param payload a PortfolioPayload object
 * @returns a Portfolio object
 */
const createPortfolio = async (payload: PortfolioPayload): Promise<Portfolio> => {
  try {
    const apiURL = baseURL;
    const response: AxiosResponse = await axiosInstance.post(apiURL, payload);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while creating portfolio.'));
    }
  }
};

/**
 * Method to fetch all portfolios
 *
 * @returns an array of Portfolio objects
 */
const fetchPortfolios = async (): Promise<Portfolio[]> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(baseURL);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while fetching portfolios.'));
    }
  }
};

/**
 * Method to update a portfolio
 * @param payload a PortfolioPayload object
 * @returns a Portfolio object
 */
const updatePortfolio = async (payload: PortfolioPayload & { id: string }): Promise<Portfolio> => {
  try {
    const apiURL = `${baseURL}${payload.id}/?user-acknowledged=true`;
    const response: AxiosResponse = await axiosInstance.put(apiURL, payload);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while updating portfolio.'));
    }
  }
};

/**
 * Method to delete a portfolio
 * @param portfolio a Portfolio object
 * @returns a Portfolio object
 */
const deletePortfolio = async (portfolio: Portfolio): Promise<void> => {
  try {
    const apiURL = `${baseURL}${portfolio.id}/?user-acknowledged=true`;
    const response: AxiosResponse = await axiosInstance.delete(apiURL);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while deleting portfolio.'));
    }
  }
};

/**
 * Fetches portfolio metrics for a given portfolio id
 * @param id - portfolio id
 * @returns PortfolioMetrics object
 */
const getPortfolioMetrics = async (id: string): Promise<PortfolioMetrics> => {
  const url = baseURL + `${id}/metrics/`;
  return await axiosInstance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching portfolio metrics'));
};

/**
 * Fetches project timelines for a given portfolio id
 * @param portfolioId - portfolio id
 * @returns ProjectTimelines object
 */
const getProjectTimelines = async (portfolioId: string): Promise<ProjectTimelines> => {
  const url = baseURL + `${portfolioId}/project-timelines/`;
  return await axiosInstance
    .get(url)
    .then((response) => response.data.project_timelines)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching project timelines for portfolio.'));
};

/**
 * Fetches epics pace tracker for a given portfolio id
 * @param portfolioId - portfolio id
 * @returns EpicsPaceTracker object
 */
const getEpicsPaceTracker = async (portfolioId: string): Promise<EpicsPaceTracker> => {
  const url = baseURL + `${portfolioId}/epics-pace-tracker/`;
  return await axiosInstance
    .get(url)
    .then((response) => response.data.epic_pace_tracker)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching epic pace tracker for portfolio.'));
};

/**
 * Method to fetch the initiative completions for a given portfolio
 *
 * @param portfolioId portfolio id
 * @param startDate start date to filter
 * @param endDate end date to filter
 * @returns a object containing initiative completions
 */
const getInitiativeCompletion = async (
  portfolioId: string,
  startDate: string,
  endDate: string
): Promise<ProcessAnalysisChartData> => {
  try {
    let url = baseURL + `${portfolioId}/initiative-completion/`;
    if (startDate) {
      url += `?start_date=${startDate}`;
    }
    if (endDate) {
      url += `&end_date=${endDate}`;
    }
    const response: AxiosResponse = await axiosInstance.get(url);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while fetching initiative completions.'));
    }
  }
};

/**
 * Method to fetch the initiative focus for a given portfolio
 *
 * @param portfolioId portfolio id
 * @param startDate start date to filter
 * @param endDate end date to filter
 * @returns a object containing initiative focus
 */
const getInitiativeFocus = async (
  portfolioId: string,
  startDate: string,
  endDate: string
): Promise<ProcessAnalysisChartData> => {
  try {
    let url = baseURL + `${portfolioId}/initiative-focus/`;
    if (startDate) {
      url += `?start_date=${startDate}`;
    }
    if (endDate) {
      url += `&end_date=${endDate}`;
    }
    const response: AxiosResponse = await axiosInstance.get(url);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while fetching initiative focus.'));
    }
  }
};

/**
 * Method to fetch measures for a given portfolio
 *
 * @param portfolioId portfolio id to fetch measures for
 * @param startDate start date to filter the data
 * @param endDate end date to filter the data
 * @returns an object containing the measures data
 */
const getPortfolioMeasures = async (
  portfolioId: string,
  startDate: string,
  endDate: string
): Promise<ProcessAnalysisMeasureComparisonData> => {
  try {
    let url = import.meta.env.VITE_APP_API_URL + `/api/portfolios/${portfolioId}/object-measures/`;
    if (startDate) {
      url += `?start_date=${startDate}`;
    }
    if (endDate) {
      url += `&end_date=${endDate}`;
    }
    const response: AxiosResponse = await axiosInstance.get(url);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while fetching measures.'));
    }
  }
};

export {
  createPortfolio,
  deletePortfolio,
  fetchPortfolios,
  getEpicsPaceTracker,
  getInitiativeCompletion,
  getInitiativeFocus,
  getPortfolioMeasures,
  getPortfolioMetrics,
  getProjectTimelines,
  updatePortfolio,
};
