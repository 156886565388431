import { Icon } from '@iconify/react/dist/iconify.js';
import { Flex, Grid } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { ProcessMapping } from '../../../api/process-client/process-client.type';
import { newCOLORS } from '../../../styles/colors';
import { useProcessScope } from '../process.hooks';
import { Text } from '../process.styled';
import { PortfolioSelect } from './portfolio-select';
import { TeamSelect } from './team-select';
import { ViewToggle } from './view-toggle';

type Props = {
  mapping: ProcessMapping | null;
};

const isGlobalScopeEnabled = import.meta.env.VITE_FEATURE_FLAG_GLOBAL_ORG_PORTFOLIO === 'true';

export function Scope({ mapping }: Props) {
  const { startDate, endDate, setScope } = useProcessScope();

  return (
    <Flex direction="column" gap={24}>
      <Text fontSize={19}>Scope</Text>

      {!isGlobalScopeEnabled && (
        <ScopeInput label="Portfolio">
          <PortfolioSelect hideLabel />
        </ScopeInput>
      )}

      <ScopeInput label="Team">
        <TeamSelect hideLabel />
      </ScopeInput>

      <ScopeInput label="Start Date">
        <MonthPickerInput
          {...{ placeholder: 'Pick start date' }}
          leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
          leftSectionPointerEvents="none"
          placeholder="Pick start date"
          value={dayjs(startDate).toDate()}
          onChange={(value) => setScope({ startDate: dayjs(value).toISOString() })}
          maxDate={dayjs(endDate).toDate() || dayjs().startOf('month').toDate()}
          style={{ width: '100%' }}
        />
      </ScopeInput>

      <ScopeInput label="End Date">
        <MonthPickerInput
          {...{ placeholder: 'Pick end date' }}
          leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
          leftSectionPointerEvents="none"
          placeholder="Pick end date"
          value={dayjs(endDate).toDate()}
          minDate={dayjs(startDate).toDate()}
          onChange={(value) => {
            const isCurrentMonth = dayjs(value).isSame(dayjs(), 'month');
            const newDate = isCurrentMonth ? dayjs() : dayjs(value).endOf('month');
            setScope({ endDate: newDate.toISOString() });
          }}
          maxDate={dayjs().toDate()}
          style={{ width: '100%' }}
        />
      </ScopeInput>

      {mapping && (
        <ScopeInput label="Full View">
          <ViewToggle mapping={mapping} />
        </ScopeInput>
      )}
    </Flex>
  );
}

function ScopeInput({ label, children }: { label: string; children: JSX.Element }) {
  return (
    <Grid>
      <Grid.Col span={3.5} style={{ display: 'flex', alignItems: 'center' }}>
        {label}
      </Grid.Col>
      <Grid.Col span={7} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        {children}
      </Grid.Col>
    </Grid>
  );
}
