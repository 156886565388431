import { ExtendedInitiative, InitiativeTaskResponse } from '../../../api/initiative-client/initiative-client.type';
import { MinifiedTask } from '../../../api/tasks-client/task-client.type';
import { removeDuplicates } from '../../tasks-table/tasks-table.helpers';
import { TaskRecord, TaskType } from '../../tasks-table/tasks-table.type';

/**
 * Retrieves the name of a project based on its ID from the given initiative.
 *
 * @param {ExtendedInitiative} initiative - the initiative containing the projects
 * @param {string} projectId - the ID of the project to retrieve the name for
 * @returns {string} the name of the project with the specified ID, or an empty string if not found
 */
const getProjectName = (initiative: ExtendedInitiative, projectId: string): string => {
  const project = initiative.projects.filter((project) => project.id === projectId);
  return project.length ? project[0].name : '';
};

/**
 * Retrieves the name of the epic with the given ID from the provided initiative.
 *
 * @param {ExtendedInitiative} initiative - the extended initiative object containing epics
 * @param {string} epicId - the ID of the epic to retrieve the name for
 * @returns {string} the name of the epic with the given ID, or an empty string if not found
 */
const getEpicName = (initiative: ExtendedInitiative, epicId: string): string => {
  const epic = initiative.epics.filter((epic) => epic.id === epicId);
  return epic.length ? epic[0].name : '';
};

/**
 * Generates a title based on the provided filters and parameters.
 *
 * @param {string | null} filter - the filter to be applied
 * @param {string | null} projectId - the project ID
 * @param {ExtendedInitiative | undefined} initiative - the initiative
 * @param {string | null} epicId - the epic ID
 * @param {string | null} year - the year
 * @param {string | null} month - the month
 * @returns {string} the generated title
 */
const makeTitle = (
  filter: string | null,
  projectId: string | null,
  initiative: ExtendedInitiative | undefined,
  epicId: string | null,
  year: string | null,
  month: string | null
): string => {
  const titleComponents = [];
  if (filter === 'active') {
    titleComponents.push('Active');
  } else if (filter === 'completed_monthly') {
    titleComponents.push(`${initiative?.name || 'Initiative'}`);
  } else if (filter === 'epic' && month === null && year === null) {
    titleComponents.push('Contributing Epic');
  } else if (filter !== 'total') {
    titleComponents.push('Completed');
  } else {
    titleComponents.push('Total');
  }
  if (projectId && initiative !== undefined && filter !== 'completed_monthly') {
    titleComponents.push(getProjectName(initiative, projectId));
  }
  if (epicId && initiative !== undefined) {
    titleComponents.push(getEpicName(initiative, epicId));
  }
  titleComponents.push('Tasks');
  if (month && year) {
    const monthStr = new Date(Number(year), Number(month) - 1).toLocaleString('default', {
      month: 'long',
    });
    titleComponents.push(`${monthStr} ${year}`);
  }
  return titleComponents.join(' - ');
};

/**
 * Formats the given initiative tasks data into an array of TaskRecord objects.
 *
 * @param {InitiativeTaskResponse[]} initiativeTasksData - The array of initiative tasks data to be formatted
 * @returns {TaskRecord[]} The formatted array of TaskRecord objects
 */
const formatTasks = (initiativeTasksData: InitiativeTaskResponse[] = []): TaskRecord[] => {
  return initiativeTasksData.reduce((acc, group) => {
    const { tasks = [] } = group;

    const formattedTasks = tasks
      .filter((task: MinifiedTask) => !acc.find((t) => t.id === task.id))
      .map(
        (task: MinifiedTask): TaskRecord => ({
          id: task.id,
          name: task.name,
          title: task.title,
          url: task.url,
          created_date: task.created_date,
          points: task.points,
          type: task.type as TaskType,
          epics: removeDuplicates(task.epics),
          initiatives: removeDuplicates(task.initiatives),
        })
      );

    return [...acc, ...formattedTasks];
  }, [] as TaskRecord[]);
};

export { formatTasks, getEpicName, getProjectName, makeTitle };
