import { styled } from '@linaria/react';
import { newCOLORS } from '../colors';
import { StyledButtonType } from './new-shared-styled-components.types';

export const StyledButton = styled.div<StyledButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props: StyledButtonType) => {
    if (props.type === 'primary') {
      return props.disable ? newCOLORS.darkerGray : props.secondColor;
    } else {
      return props.disable ? newCOLORS.gray : props.firstColor;
    }
  }};
  background-color: ${(props: StyledButtonType) => {
    if (props.type === 'primary') {
      return props.disable ? newCOLORS.gray : props.firstColor;
    } else {
      return props.disable ? newCOLORS.lighterGray : props.secondColor;
    }
  }};
  padding: ${(props: StyledButtonType) => {
    if (props.size === 'large') {
      return '8px 16px';
    } else if (props.size === 'medium') {
      return '6px 12px';
    } else if (props.size === 'small') {
      return '4px 8px';
    } else {
      return '6px 12px';
    }
  }};
  font-size: ${(props: StyledButtonType) => {
    if (props.size === 'large') {
      return '24px';
    } else if (props.size === 'medium') {
      return '16px';
    } else if (props.size === 'small') {
      return '12px';
    } else {
      return '16px';
    }
  }};
  border: ${(props: StyledButtonType) => {
    if (props.type === 'primary') {
      return '0px solid';
    } else {
      return '1px solid';
    }
  }};
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;

  &:hover {
    color: ${(props: StyledButtonType) => {
      if (props.type === 'secondary') {
        return `${props.disable ? newCOLORS.gray : props.firstColor}80`;
      } else {
        return props.disable ? newCOLORS.darkerGray : props.secondColor;
      }
    }};
    background-color: ${(props: StyledButtonType) => {
      if (props.type === 'primary') {
        return `${props.disable ? newCOLORS.gray : props.firstColor}80`;
      } else {
        return props.disable ? newCOLORS.lighterGray : props.secondColor;
      }
    }};
  }
`;

export const SuperText = styled.div`
  font-family: 'Figtree';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 46px;
`;

export const PageHeader = styled.h1`
  color: #444;
  font-family: Figtree;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Heading = styled.div`
  font-family: 'Figtree';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 29px;
`;

export const SmallerHeading = styled.div`
  font-family: 'Figtree';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 19px;
`;

export const StandardText = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  line-height: 135%;
`;

export const StandardTextBold = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  font-weight: 800;
  line-height: 135%;
`;

export const SmallText = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
`;

export const UppercaseCallout = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
`;
