import { styled } from '@linaria/react';
import { Checkbox, LoadingOverlay, Select, Title } from '@mantine/core';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { icons } from '../../../assets/icons/icons';
import { ComboboxSelect } from '../../../components/combobox-select/combobox-select';
import { DownloadPNGButton } from '../../../components/download-png-button';
import { findClosestMantineColor } from '../../../helpers/color-helpers/color-helpers';
import { canvasHtmlDownload } from '../../../helpers/image-downloader/image-downloader';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDateRange, useEntities } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { SelectContainer, SelectLabel, selectData } from '../../strategy/initiative-performance/collapsible-section';
import { RowStart, TD, TH } from '../key-measures/key-measures-over-time';
import { Pills } from '../pills/pills';
import { ProcessAnalysisChart } from '../process-analysis-chart';
import {
  ITEM_COLORS,
  getPills,
  handleChangeEntity,
  handleChangeTrend,
  matchEntitiesWithColor,
} from '../process-analysis.helpers';
import { Entities, MetricType } from '../process-analysis.type';
import { useInitiativeCompletionData } from './initiative-completion.hooks';
import { DropShadowContainer } from './initiative-overview';
import { sortInitiatives } from './initiative-sections.helpers';

export function InitiativeCompletion() {
  const { portfolios = [], teams = [] } = useGlobalStore();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();

  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);

  const [metric, setMetric] = useState<MetricType>(MetricType.Tasks);
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const [selectedTrends, setSelectedTrends] = useState<string[]>([]);
  const pills = getPills(entities, activeTab, portfolios, teams, startDate, endDate);

  const handleDownload = () => canvasHtmlDownload(pills.join('_'), downloadRef, exceptionRef);

  const { data: initiativeCompletions, isFetching } = useInitiativeCompletionData();
  const initiativeNames = sortInitiatives(initiativeCompletions);

  const [displayedInitiatives, setDisplayedInitiatives] = useState<string[]>([]);
  const [notNullInitiatives, setNotNullInitiatives] = useState<string[]>([]);
  const [userClearedInitiatives, setUserClearedInitiatives] = useState(false);

  useEffect(() => {
    setDisplayedInitiatives([]);
    setSelectedEntities([]);
    setSelectedTrends([]);
    setUserClearedInitiatives(false);
  }, [activeTab, entities]);

  useEffect(() => {
    if (initiativeCompletions) {
      // Filter out initiatives with null or undefined average values
      const notNull = initiativeNames.filter((name) => {
        const lastMonth = initiativeCompletions?.[name]?.['average']?.[metric] ?? null;
        return lastMonth !== null && lastMonth !== undefined && lastMonth > 0;
      });
      setNotNullInitiatives(notNull);
      // Only set displayed initiatives if it's currently empty and not deliberately cleared by the user
      if (displayedInitiatives.length === 0 && !userClearedInitiatives) {
        setDisplayedInitiatives(notNull);
      }
    }
  }, [initiativeCompletions, initiativeNames, metric, displayedInitiatives.length, userClearedInitiatives]);

  useDeepCompareEffect(() => {
    if (notNullInitiatives.length > 0) {
      setSelectedEntities([notNullInitiatives[0]]);
    }
  }, [notNullInitiatives]);

  const availableInitiatives = initiativeNames.filter((name) => !displayedInitiatives.includes(name));

  const handleRemoveInitiative = (initiativeName: string) => {
    const newDisplayedInitiatives = displayedInitiatives.filter((name) => name !== initiativeName);
    setDisplayedInitiatives(newDisplayedInitiatives);
    setSelectedEntities(selectedEntities.filter((name) => name !== initiativeName));
    setSelectedTrends(selectedTrends.filter((name) => name !== initiativeName));

    if (newDisplayedInitiatives.length === 0) {
      setUserClearedInitiatives(true);
    }
  };

  return (
    <DropShadowContainer ref={downloadRef}>
      <LoadingOverlay visible={isFetching} overlayProps={{ blur: 2 }} style={{ zIndex: 200 }} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '12px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '5px' }}>
          <Pills pillText={pills} />
          <SelectContainer style={{ marginLeft: 'auto' }}>
            <SelectLabel>Value</SelectLabel>
            <Select
              label=""
              size="xs"
              style={{ width: 140 }}
              value={metric}
              data={selectData}
              onChange={(value) => setMetric(value as MetricType)}
            />
          </SelectContainer>
          <div ref={exceptionRef}>
            {<DownloadPNGButton handleDownload={handleDownload} exceptionRef={exceptionRef} />}
          </div>
        </div>
        <Title>Initiative Completion</Title>
      </div>
      {initiativeCompletions ? (
        <ContentContainer>
          <LegendContainer>
            <LegendScrollContainer>
              {displayedInitiatives.length > 0 ? (
                <LegendTable>
                  <thead>
                    <tr>
                      <StickyTH>Initiatives</StickyTH>
                      <StickyTH>Line</StickyTH>
                      <StickyTH>Trend</StickyTH>
                      <StickyTH></StickyTH>
                    </tr>
                    <tr style={{ height: '16px' }}></tr>
                  </thead>
                  <tbody>
                    {displayedInitiatives.map((initiativeName, index) => {
                      const color = ITEM_COLORS[index % ITEM_COLORS.length];
                      return (
                        <Fragment key={index}>
                          <tr>
                            <RowStart color={color}>{initiativeName}</RowStart>
                            <TD>
                              <Checkbox
                                color={findClosestMantineColor(color)}
                                checked={selectedEntities.includes(initiativeName)}
                                onChange={(event) =>
                                  handleChangeEntity(
                                    event.currentTarget.checked,
                                    initiativeName,
                                    selectedEntities,
                                    setSelectedEntities
                                  )
                                }
                                size="xs"
                                style={{ paddingBottom: '2px' }}
                              />
                            </TD>
                            <TD>
                              <Checkbox
                                color={findClosestMantineColor(color)}
                                checked={selectedTrends.includes(initiativeName)}
                                onChange={(event) =>
                                  handleChangeTrend(
                                    event.currentTarget.checked,
                                    initiativeName,
                                    selectedTrends,
                                    setSelectedTrends
                                  )
                                }
                                size="xs"
                                style={{ paddingBottom: '2px' }}
                              />
                            </TD>
                            <TD>
                              <DeleteIcon
                                src={icons.iconDelete}
                                onClick={() => handleRemoveInitiative(initiativeName)}
                                alt="delete"
                              />
                            </TD>
                          </tr>
                          <tr style={{ height: '4px' }}></tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                </LegendTable>
              ) : (
                <NoInitiativesPlaceholder>
                  {initiativeNames.length > 0
                    ? 'No initiatives selected. Please select an initiative from the dropdown below.'
                    : `No initiatives found for the selected ${activeTab.slice(0, -1)}.`}
                </NoInitiativesPlaceholder>
              )}
            </LegendScrollContainer>
            {availableInitiatives.length > 0 && (
              <DropdownContainer>
                <ComboboxSelect
                  options={availableInitiatives.map((name) => ({ value: name, label: name }))}
                  onChange={(value) => {
                    setDisplayedInitiatives([...displayedInitiatives, value as string]);
                  }}
                  placeholder="Select initiative"
                />
              </DropdownContainer>
            )}
          </LegendContainer>
          <ChartContainer>
            <ProcessAnalysisChart
              initiativeCompletions={initiativeCompletions}
              metric={metric}
              entitiesWithColor={matchEntitiesWithColor(selectedEntities, displayedInitiatives) as Entities}
              entityTrendsWithColor={matchEntitiesWithColor(selectedTrends, displayedInitiatives) as Entities}
              type={'completion'}
            />
          </ChartContainer>
        </ContentContainer>
      ) : null}
    </DropShadowContainer>
  );
}

export const LegendContainer = styled.div`
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const LegendScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
`;

export const DropdownContainer = styled.div`
  background-color: white;
  padding: 8px 0;
  border-top: 1px solid ${newCOLORS.lightGray};
`;

export const StickyTH = styled(TH)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 400px;
`;

export const LegendTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

export const DeleteIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const ChartContainer = styled.div`
  flex: 1;
  min-width: 0;
  height: 100%;
`;

export const NoInitiativesPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 16px;
  text-align: center;
  color: ${newCOLORS.gray};
  font-style: italic;
`;
