import dayjs from 'dayjs';
import { Portfolio } from '../../api/portfolio-client/portfolio-client.type';
import { ProjectsResponse } from '../../api/projects-client/projects-client.type';
import { EntityType, Measure, MeasureUnits } from '../../api/work-periods-client/work-periods-client.type';
import { newCOLORS } from '../../styles/colors';
import { Tab } from './process-analysis.type';

/**
 * Takes a text, seperated by underscores and normalizes to be printed to the screeen
 *
 * @param text: string seperated by underscores
 * @returns renderable text seperated by spaces
 */
function toDisplayText(text: string): string {
  if (!text) {
    return '';
  }

  return text
    .split('_')
    .map((string: string) => {
      return string[0].toUpperCase() + string.substring(1);
    })
    .join(' ');
}

const ITEM_COLORS = [
  newCOLORS.green,
  newCOLORS.coral,
  newCOLORS.blue,
  newCOLORS.aqua,
  newCOLORS.lighterGreen,
  newCOLORS.pink,
  newCOLORS.yellow,
  newCOLORS.orangeRed,
  newCOLORS.purple,
  newCOLORS.magenta,
];

const MEASURE_COLOR_MAP = {
  [Measure.Complexity]: newCOLORS.lighterGreen,
  [Measure.CycleTime]: newCOLORS.yellow,
  [Measure.CycleTimeInconsistency]: newCOLORS.coral,
  [Measure.DeclinedChangeRequests]: newCOLORS.brown,
  [Measure.Independence]: newCOLORS.pink,
  [Measure.LeadTime]: newCOLORS.green,
  [Measure.MultiplePointChangesPerTask]: newCOLORS.blue,
  [Measure.Quality]: newCOLORS.red,
  [Measure.ReactionTime]: newCOLORS.aqua,
  [Measure.ReviewTime]: newCOLORS.tangerine,
  [Measure.ScopeCreep]: newCOLORS.lightBlue,
  [Measure.StaleTasks]: newCOLORS.pink,
  [Measure.Strategy]: newCOLORS.lightOrange,
  [Measure.TasksMovingBackwards]: newCOLORS.orangeRed,
  [Measure.TechDebt]: newCOLORS.purple,
  [Measure.Throughput]: newCOLORS.indigo,
  [Measure.UntestedTasks]: newCOLORS.magenta,
  [Measure.Velocity]: newCOLORS.red,
} as { [key in Measure]: string };

/**
 * Returns a color for a given measure. If the measure is known, it will be
 * returned from the MEASURE_COLOR_MAP. If the measure is unknown, a random
 * color will be generated.
 *
 * @param {measure} - The measure name
 * @returns {string} A color string
 */
const getMeasureColor = (measure: Measure): string => {
  const defaultColor = MEASURE_COLOR_MAP[measure];

  if (!defaultColor) {
    return getRandomColor();
  }

  return defaultColor;
};

/**
 * Returns a random color from the ITEM_COLORS array.
 *
 * @returns {string} A random color string
 */
const getRandomColor = (): string => {
  return ITEM_COLORS[Math.floor(Math.random() * ITEM_COLORS.length)];
};

/**
 * Returns the entity type based on the active tab.
 *
 * @param {Tab} activeTab - The active tab.
 * @return {EntityType} The entity type corresponding to the active tab.
 */
function getEntityType(activeTab: Tab): EntityType {
  const entityTypes = {
    [Tab.Portfolios]: EntityType.Portfolio,
    [Tab.Teams]: EntityType.Project,
    [Tab.Boards]: EntityType.Subproject,
    [Tab.WorkPeriods]: EntityType.Subproject,
  };

  return entityTypes[activeTab];
}

/**
 * Returns the title based on the active tab, portfolios, teams, and header.
 *
 * @param {Portfolio[]} portfolios - An array of portfolios.
 * @param {ProjectsResponse[]} teams - An array of teams.
 * @param {Tab} activeTab - The active tab.
 * @param {string} header - The header.
 * @return {string | undefined} The title corresponding to the active tab, portfolios, teams, and header.
 */
function getTitle(
  portfolios: Portfolio[],
  teams: ProjectsResponse[],
  activeTab: Tab,
  header: string
): string | undefined {
  switch (activeTab) {
    case Tab.Portfolios:
      return portfolios?.find((portfolio) => portfolio.id === header)?.name;
    case Tab.Teams:
      return teams?.find((team) => team.id === header)?.name;
    case Tab.Boards:
      return teams
        ?.map((t) => t.subprojects)
        .flat()
        .find((board) => board.id === header)?.name;
  }
}

/**
 * Returns the column names for the entity (portfolio, team, or board) given the activeTab and header
 *
 * @param portfolios: Portfolio[] - the list of portfolios
 * @param teams: ProjectsResponse[] - the list of teams
 * @param activeTab: 'portfolios' | 'teams' | 'boards' - the active tab
 * @param columnHeaders: string[] - the column headers
 * @returns string[] - the column names
 */
function getColumnNames(
  portfolios: Portfolio[],
  teams: ProjectsResponse[],
  activeTab: Tab,
  columnHeaders: string[]
): string[] {
  switch (activeTab) {
    case Tab.Portfolios:
      return columnHeaders.map((id) => portfolios?.find((p) => p.id === id)?.name).filter(Boolean) as string[];
    case Tab.Teams:
      return columnHeaders.map((id) => teams?.find((t) => t.id === id)?.name).filter(Boolean) as string[];
    case Tab.Boards:
      return getBoardsColumnHeaders(teams, columnHeaders);
    default:
      return [];
  }
}

/**
 * Returns an array of board names based on the given teams and column headers.
 *
 * @param {ProjectsResponse[]} teams - The list of teams.
 * @param {string[]} columnHeaders - The column headers.
 * @return {string[]} An array of board names.
 */
const getBoardsColumnHeaders = (teams: ProjectsResponse[], columnHeaders: string[]): string[] => {
  const subprojects = teams?.map((t) => t.subprojects).flat();

  return columnHeaders.map((id) => subprojects?.find((b) => b.id === id)?.name).filter(Boolean) as string[];
};

/**
 * Normalizes the active tab and measure into a user friendly measure display.
 *
 * @param activeTab: string
 * @returns a renderable measure display
 */
const getMeasureDisplay = (activeTab: string): string => {
  const labels = {
    [Tab.Portfolios]: 'Portfolio Performance',
    [Tab.Teams]: 'Team Performance',
    [Tab.Boards]: 'Board Performance',
  };

  return labels[activeTab as keyof typeof labels];
};

/**
 * Method to get the pills information based on the selected attributes
 *
 * @param entities: string[] - portfolios or teams or boards
 * @param activeTab: Tab - selected active tab
 * @param portfolios: array - list of portfolios
 * @param teams: array - list of teams/projects
 * @param startDate: Date - start date of comparison
 * @param endDate: Date - end date of comparison
 * @returns a list of strings
 */
const getPills = (
  entities: string[],
  activeTab: Tab,
  portfolios: Portfolio[],
  teams: ProjectsResponse[],
  startDate: Date,
  endDate: Date
): string[] => {
  const measureLabel = getMeasureDisplay(activeTab);

  return entities && entities.length
    ? [
        entities.length > 1
          ? `Multiple ${activeTab ? activeTab[0].toUpperCase() + activeTab.substring(1) : ''}`
          : getTitle(portfolios as Portfolio[], teams as ProjectsResponse[], activeTab, entities[0]) || entities[0],
        measureLabel,
        `${dayjs(startDate).format('MMM YYYY')} - ${dayjs(endDate).format('MMM YYYY')}`,
      ]
    : [measureLabel, `${dayjs(startDate).format('MMM YYYY')} - ${dayjs(endDate).format('MMM YYYY')}`];
};

/**
 * Handles the change of the selected entity
 *
 * @param checked - whether checked or not
 * @param header  - the header of the entity
 * @param selectedEntities - list of already selected entities
 * @param setSelectedEntities - function to set the selected entities
 * @returns void
 */
function handleChangeEntity(
  checked: boolean,
  header: string,
  selectedEntities: string[],
  setSelectedEntities: (selectedEntities: string[]) => void
): void {
  if (checked) {
    setSelectedEntities([...selectedEntities, header]);
  } else {
    setSelectedEntities(selectedEntities.filter((entity) => entity !== header));
  }
}

/**
 * Handles the change of the selected trend
 *
 * @param checked - whether checked or not
 * @param header - the header of the trend
 * @param color - color of the trend
 * @param selectedTrends - list of already selected trends
 * @param setSelectedTrends - function to set the selected trends
 * @returns void
 */
function handleChangeTrend(
  checked: boolean,
  header: string,
  selectedTrends: string[],
  setSelectedTrends: (selectedTrends: string[]) => void
): void {
  if (checked) {
    setSelectedTrends([...selectedTrends, header]);
  } else {
    setSelectedTrends(selectedTrends.filter((trend) => trend !== header));
  }
}

/** matches selected entities with a color based on the position in the list of all entities
 *
 * @param selectedEntities: string[] - list of selected entities
 * @param allEntities: string[] - list of all entities
 * @returns an object with the selected entities as keys and the color as values
 */
function matchEntitiesWithColor(selectedEntities: string[], allEntities: string[]): { [key: string]: string } {
  return selectedEntities.reduce((acc: { [key: string]: string }, entity) => {
    const index = allEntities.indexOf(entity);
    if (index === -1) {
      return acc;
    }
    acc[entity] = ITEM_COLORS[index % ITEM_COLORS.length];
    return acc;
  }, {});
}

/**
 * Returns a string label for the given unit of measurement
 *
 * @param {MeasureUnits} units - the unit of measurement
 * @returns {string} - a string label for the given unit of measurement
 */
const getUnitsLabel = (units: MeasureUnits): string => {
  switch (units) {
    case MeasureUnits.Percentage:
      return '%';
    case MeasureUnits.Tasks:
      return 'tasks';
    case MeasureUnits.Points:
      return 'points';
    case MeasureUnits.Hours:
      return 'days';
    default:
      return 'units';
  }
};

export {
  getColumnNames,
  getEntityType,
  getMeasureColor,
  getPills,
  getTitle,
  getUnitsLabel,
  handleChangeEntity,
  handleChangeTrend,
  ITEM_COLORS,
  matchEntitiesWithColor,
  MEASURE_COLOR_MAP,
  toDisplayText,
};
