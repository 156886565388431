import { QueryObserverResult, useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getAvailableMeasures, getWorkPeriods, getWorkPeriodsMeasure } from './work-periods-client';
import {
  AvailableMeasuresResponse,
  EntityType,
  GetAvailableMeasuresPayload,
  GetWorkPeriodsPayload,
  MeasureDataByEntity,
  MeasureDataResponse,
  UseWorkPeriodsMeasurePayloadMultiple,
  UseWorkPeriodsMeasurePayloadSingular,
  WorkPeriod,
} from './work-periods-client.type';

/**
 * Custom hook for fetching a list of available measures for the given scope and entity
 *
 * @param {GetAvailableMeasuresPayload} payload - The payload that is passed to getAvailableMeasures
 * @param {string} payload.scope - The scope of the available measures
 * @param {string} [payload.portfolio_id] - The ID of the portfolio
 * @param {string} [payload.project_id] - The ID of the project
 * @param {string} [payload.subproject_id] - The ID of the subproject
 * @param {UseQueryOptions<AvailableMeasuresResponse>} [options] - The options that are passed to useQuery
 * @return {{ data: AvailableMeasuresResponse | undefined; query: QueryObserverResult<AvailableMeasuresResponse> }} - The data and the query object
 */
const useAvailableMeasures = (
  { scope, portfolio_id, project_id, subproject_id }: GetAvailableMeasuresPayload,
  options?: UseQueryOptions<AvailableMeasuresResponse>
): { data: AvailableMeasuresResponse | undefined; query: QueryObserverResult<AvailableMeasuresResponse> } => {
  const query = useQuery({
    queryKey: ['available-measures', scope, portfolio_id, project_id, subproject_id],
    queryFn: () => getAvailableMeasures({ scope, portfolio_id, project_id, subproject_id }),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Custom hook that fetches work periods based on the provided parameters.
 *
 * @param {GetWorkPeriodsPayload} params - The parameters for fetching work periods.
 * @param {string} params.subproject_id - The ID of the subproject.
 * @param {string} [params.parent] - The ID of the parent work period.
 * @param {string} [params.external_id] - The external ID of the work period.
 * @param {string} [params.time_span] - The time span for the work periods.
 * @param {string} [params.time_tense] - The time tense for the work periods.
 * @return {{ data: WorkPeriod[] | undefined, query: UseQueryResult<WorkPeriod[]> }} - An object containing the fetched work periods and the query result.
 */
const useWorkPeriods = (
  { subproject_id, parent, external_id, time_span, time_tense }: GetWorkPeriodsPayload,
  options?: UseQueryOptions<WorkPeriod[]>
): { data: WorkPeriod[] | undefined; query: QueryObserverResult<WorkPeriod[]> } => {
  const query = useQuery({
    queryKey: ['work-periods', subproject_id, parent, external_id, time_span, time_tense],
    queryFn: () => getWorkPeriods({ subproject_id, parent, external_id, time_span, time_tense }),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Custom hook that fetches work period measures based on the provided parameters for a multiple entities.
 *
 * @param {UseWorkPeriodsMeasurePayloadMultiple} payload - The parameters for fetching the work period measures.
 * @param {EntityType} payload.entity - The entity type.
 * @param {string[]} payload.ids - The IDs of the entities.
 * @param {string} payload.start_date - The start date for the measures.
 * @param {string} payload.end_date - The end date for the measures.
 * @param {Measure} payload.measure_name - The name of the measure.
 * @param {string} payload.transformer - The transformer for the measure.
 * @param {boolean} payload.force_recalculation - Flag to force recalculation.
 * @param {UseQueryOptions<MeasureDataResponse>} [options] - Additional options for the queries.
 * @return {{ data: MeasureDataByEntity | undefined; isFetching: boolean; queries: QueryObserverResult<MeasureDataResponse>[] }} - An object containing the fetched measures data, a flag indicating if any queries are still fetching, and the query results.
 */
const useWorkPeriodsMeasureMultiple = (
  payload: UseWorkPeriodsMeasurePayloadMultiple,
  options?: UseQueryOptions<MeasureDataResponse>
): {
  data: MeasureDataByEntity | undefined;
  isFetching: boolean;
  queries: QueryObserverResult<MeasureDataResponse>[];
} => {
  const { entity, ids, ...params } = payload;

  const entityParams = {
    [EntityType.Portfolio]: 'portfolio_id',
    [EntityType.Project]: 'project_id',
    [EntityType.Subproject]: 'subproject_id',
  };

  const queryOptions = {
    staleTime: 1000 * 60 * 5,
    ...options,
  };

  const queries = useQueries({
    queries: ids.map((id) => ({
      queryKey: [
        'work-periods-measure-multiple',
        payload.entity,
        id,
        payload.start_date,
        payload.end_date,
        payload.measure_name,
        payload.time_allocation_type,
        payload.transformer,
        payload.force_recalculation,
      ],
      queryFn: () =>
        getWorkPeriodsMeasure({ ...params, [entityParams[payload.entity]]: id }) as Promise<MeasureDataResponse>,
      ...queryOptions,
    })),
  });

  const measuresData = queries.reduce((acc, query, index) => {
    if (query.data) {
      return { ...acc, [ids[index]]: query.data };
    }

    return acc;
  }, {} as MeasureDataByEntity);

  const isFetching = queries.some((query) => query.isFetching);

  return { data: measuresData, isFetching, queries };
};

/**
 * Custom hook that fetches a work period measure based on the provided parameters for a singular entity.
 *
 * @param {UseWorkPeriodsMeasurePayloadSingular} payload - The parameters for fetching the work period measures.
 * @param {EntityType} payload.entity - The entity type.
 * @param {string} payload.subproject_id - The ID of the subproject.
 * @param {string} [payload.work_period_id] - The ID of the work period.
 * @param {string} [payload.project_id] - The ID of the project.
 * @param {string} [payload.portfolio_id] - The ID of the portfolio.
 * @param {string} payload.start_date - The start date for the measure.
 * @param {string} payload.end_date - The end date for the measure.
 * @param {Measure[]} payload.measure_name - The name of the measure.
 * @param {string} [payload.time_allocation_type] - The time allocation type.
 * @param {string} [payload.transformer] - The transformer for the measure.
 * @param {boolean} [payload.force_recalculation] - Flag to force recalculation.
 * @param {UseQueryOptions<T>} [options] - Additional options for the queries.
 * @return {{ data: T | undefined; query: QueryObserverResult<T> }} - An object containing the fetched measures data and the query result.
 */
const useWorkPeriodsMeasureSingular = <T>(
  payload: UseWorkPeriodsMeasurePayloadSingular,
  options?: UseQueryOptions<T>
): {
  data: T | undefined;
  query: QueryObserverResult<T>;
} => {
  const query = useQuery({
    queryKey: ['work-periods-measure-singular', ...Object.values(payload)],
    queryFn: () => getWorkPeriodsMeasure<T>({ ...payload }) as Promise<T>,
    ...options,
  });

  return { data: query.data, query };
};

export { useAvailableMeasures, useWorkPeriods, useWorkPeriodsMeasureMultiple, useWorkPeriodsMeasureSingular };
