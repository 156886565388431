import { styled } from '@linaria/react';
import { Checkbox, LoadingOverlay, Title } from '@mantine/core';
import { Fragment, useRef, useState } from 'react';
import { Measure, MeasureUnits } from '../../../api/work-periods-client/work-periods-client.type';
import { icons } from '../../../assets/icons/icons';
import { ComboboxSelect } from '../../../components/combobox-select/combobox-select';
import { DownloadPNGButton } from '../../../components/download-png-button';
import { findClosestMantineColor } from '../../../helpers/color-helpers/color-helpers';
import { canvasHtmlDownload } from '../../../helpers/image-downloader/image-downloader';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  useAvailableMeasureOptions,
  useAvailableMeasureUnits,
  useDateRange,
  useEntities,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { DropShadowContainer } from '../initiative-sections/initiative-overview';
import { RowStart, TD, TH } from '../key-measures/key-measures-over-time';
import { MeasureLabel } from '../measure-label';
import { Pills } from '../pills/pills';
import { getMeasureColor, getPills, toDisplayText } from '../process-analysis.helpers';
import { ProcessAnalysisMeasuresComparisonChart } from './measure-comparison-chart';
import { getDefaultMeasures, getMeasuresWithColors } from './measure-comparison.helpers';
import { useMeasureComparisonData } from './measure-comparison.hooks';

export function MeasureComparison() {
  const { portfolios = [], teams = [] } = useGlobalStore();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures);
  const entities = useEntities();
  const { startDate, endDate } = useDateRange();
  const availableMeasureOptions = useAvailableMeasureOptions();
  const unitsByMeasureName = useAvailableMeasureUnits();

  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);
  const { data: measureData, isFetching } = useMeasureComparisonData();
  const defaultMeasures = getDefaultMeasures();
  const [measures, setMeasures] = useState<Measure[]>(defaultMeasures);
  const [selectedMeasures, setSelectedMeasures] = useState<Measure[]>([defaultMeasures[0]]);
  const [selectedTrends, setSelectedTrends] = useState<Measure[]>([]);
  const pills = getPills(entities, activeTab, portfolios, teams, startDate, endDate);

  const activeMeasureOptions = availableMeasureOptions.filter((option) => !measures.includes(option.value as Measure));

  const handleDownload = () => canvasHtmlDownload(pills.join('_'), downloadRef, exceptionRef);

  return (
    <DropShadowContainer ref={downloadRef}>
      <LoadingOverlay visible={isFetching} overlayProps={{ blur: 2 }} style={{ zIndex: 200 }} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '12px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '5px' }}>
          <Pills pillText={pills} />
          <div ref={exceptionRef}>
            {<DownloadPNGButton handleDownload={handleDownload} exceptionRef={exceptionRef} />}
          </div>
        </div>
        <Title>Measure Comparison</Title>
      </div>
      {measureData ? (
        <ContentContainer>
          <LegendContainer>
            <LegendScrollContainer>
              <LegendTable>
                <thead>
                  <tr>
                    <StickyTH>Measures</StickyTH>
                    <StickyTH>Line</StickyTH>
                    <StickyTH>Trend</StickyTH>
                    <StickyTH></StickyTH>
                  </tr>
                  <tr style={{ height: '16px' }}></tr>
                </thead>
                <tbody>
                  {measures.map((measure, index) => {
                    const color = getMeasureColor(measure);
                    const measureMetadata = availableMeasures.find((m) => m.measure_name === measure);

                    return (
                      <Fragment key={index}>
                        <tr>
                          <RowStart color={color}>
                            <MeasureLabel
                              label={measureMetadata?.measure_title || toDisplayText(measure)}
                              tooltip={measureMetadata?.measure_description}
                            />
                          </RowStart>
                          <TD>
                            <Checkbox
                              color={findClosestMantineColor(color)}
                              checked={selectedMeasures.includes(measure)}
                              onChange={(event) => {
                                const { checked } = event.currentTarget;
                                if (checked) {
                                  setSelectedMeasures([...selectedMeasures, measure]);
                                } else {
                                  setSelectedMeasures(selectedMeasures.filter((val) => val !== measure));
                                }
                              }}
                              size="xs"
                              style={{ paddingBottom: '2px' }}
                            />
                          </TD>
                          <TD>
                            <Checkbox
                              color={findClosestMantineColor(color)}
                              checked={selectedTrends.includes(measure)}
                              onChange={(event) => {
                                const { checked } = event.currentTarget;
                                if (checked) {
                                  setSelectedTrends([...selectedTrends, measure]);
                                } else {
                                  setSelectedTrends(selectedTrends.filter((val) => val !== measure));
                                }
                              }}
                              size="xs"
                              style={{ paddingBottom: '2px' }}
                            />
                          </TD>
                          <TD>
                            <DeleteIcon
                              src={icons.iconDelete}
                              onClick={() => {
                                setMeasures(measures.filter((val) => val !== measure));
                                setSelectedMeasures(selectedMeasures.filter((val) => val !== measure));
                                setSelectedTrends(selectedTrends.filter((val) => val !== measure));
                              }}
                              alt="delete"
                            />
                          </TD>
                        </tr>
                        <tr style={{ height: '4px' }}></tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </LegendTable>
            </LegendScrollContainer>
            {activeMeasureOptions.length > 0 && (
              <DropdownContainer>
                <ComboboxSelect
                  options={activeMeasureOptions}
                  onChange={(value) => {
                    setMeasures([...measures, value as Measure]);
                  }}
                  placeholder="Select measure"
                />
              </DropdownContainer>
            )}
          </LegendContainer>
          <ChartContainer>
            <ProcessAnalysisMeasuresComparisonChart
              measureData={measureData}
              measureUnitMap={unitsByMeasureName as { [measureName in Measure]: MeasureUnits }}
              measuresWithColor={getMeasuresWithColors(selectedMeasures)}
              trendsWithColor={getMeasuresWithColors(selectedTrends)}
            />
          </ChartContainer>
        </ContentContainer>
      ) : null}
    </DropShadowContainer>
  );
}

const LegendContainer = styled.div`
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LegendScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding-right: 16px;
`;

const DropdownContainer = styled.div`
  background-color: white;
  padding: 8px 0;
  border-top: 1px solid ${newCOLORS.lightGray};
`;

const StickyTH = styled(TH)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 400px;
`;

const LegendTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

const DeleteIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const ChartContainer = styled.div`
  flex: 1;
  min-width: 0;
  height: 100%;
`;
