import {
  BurnsDataOverTime,
  FlowByPaceDataOverTime,
  FlowByPhaseDataOverTime,
  HistoricalBurnsDataOverTime,
  Measure,
  PaceStatus,
} from '../../../api/work-periods-client/work-periods-client.type';

enum AssessmentChart {
  Phase = 'Phase',
  Pace = 'Pace',
  Delivery = 'Delivery',
}

enum Metric {
  Tasks = 'Tasks',
  Points = 'Points',
}

type KeyProcessMeasuresData = {
  [Measure.Strategy]: number;
  [Measure.Complexity]: number;
  [Measure.Quality]: number;
  [Measure.ScopeCreep]: number;
  [Measure.Readiness]: number;
  [Measure.Independence]: number;
  [Measure.ReviewTime]: number;
  [Measure.DeclinedChangeRequests]: number;
};

type FlowData = {
  flow_by_phase?: FlowByPhaseDataOverTime;
  flow_by_pace?: FlowByPaceDataOverTime;
  burns?: BurnsDataOverTime;
  historical_burns?: HistoricalBurnsDataOverTime;
};

type WorkPeriodFlowDataPoint = {
  backlog: number;
  blocked: number;
  date: string;
  deployable: number;
  done: number;
  review: number;
  test: number;
  wip: number;
};

type WorkPeriodMetricData = {
  data_points: WorkPeriodFlowDataPoint[];
};

type WorkPeriodBurnsAndFlows = {
  flows_tasks: WorkPeriodMetricData;
  flows_points: WorkPeriodMetricData;
};

type WorkPeriodTasksPaceDataPoint = {
  date: string;
  weekend_or_holiday: boolean;
  tasks_complete: number;
  tasks_doing: number;
  tasks_ideal: number;
  tasks_original_plan: number;
  tasks_remaining: number;
  tasks_status: PaceStatus;
  tasks_total: number;
};

type WorkPeriodPointsPaceDataPoint = {
  date: string;
  weekend_or_holiday: boolean;
  points_complete: number;
  points_doing: number;
  points_ideal: number;
  points_original_plan: number;
  points_remaining: number;
  points_status: PaceStatus;
  points_total: number;
};

type WorkPeriodTasksPaceData = {
  data_points: WorkPeriodTasksPaceDataPoint[];
};

type WorkPeriodPointsPaceData = {
  data_points: WorkPeriodPointsPaceDataPoint[];
};

type WorkPeriodBurnsAndFlowsByPace = {
  flow_of_work_tasks_by_pace: WorkPeriodTasksPaceData;
  flow_of_work_points_by_pace: WorkPeriodPointsPaceData;
};

type DeliveryConfidenceData = {
  burns?: BurnsDataOverTime;
  historical_burns?: HistoricalBurnsDataOverTime;
};

export { AssessmentChart, Metric };
export type {
  DeliveryConfidenceData,
  FlowData,
  KeyProcessMeasuresData,
  WorkPeriodBurnsAndFlows,
  WorkPeriodBurnsAndFlowsByPace,
  WorkPeriodFlowDataPoint,
  WorkPeriodMetricData,
  WorkPeriodPointsPaceData,
  WorkPeriodPointsPaceDataPoint,
  WorkPeriodTasksPaceData,
  WorkPeriodTasksPaceDataPoint,
};
