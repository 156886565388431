import { Icon } from '@iconify/react/dist/iconify.js';
import { ActionIcon, ButtonProps as MantineButtonProps, Paper, Text, TextInput, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Markdown from 'react-markdown';

import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { canUseAI } from '../../api/agents-client/agent-client';
import { UseAIResult } from '../../api/agents-client/agent-client.class';
import { askAgent, createAgentWebsocket } from '../../api/agents-client/agents-websockets';
import { useOrganizationId } from '../../helpers/auth-helpers/auth.hooks';
import { addOpacityToColor } from '../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../styles/colors';
import { ColoredMark } from './colored-mark';

interface InsightsProps extends MantineButtonProps {
  // Data is going to take any form and fuction of the page. I found it. The only use for Any
  data: any;
  // The page that the insights component is embeded on.
  context: string;
  // Only start the insights when you have full data
  isFullData?: boolean;
}

export function Insights(props: InsightsProps) {
  // Handles the first render
  const orgId = useOrganizationId();
  const [renderInsights, setRenderInsights] = useState(false);
  const [useAI, setUseAI] = useState(false);
  const [isShowChat, { toggle: toggleShowChat }] = useDisclosure(false);
  const [isActiveQuestion, setIsActiveQuestion] = useState(false);
  const [messages, setMessages] = useState<string[]>(['']);
  const [question, setQuestion] = useState('');
  const [threadId, setThreadId] = useState('');
  const [runOnce, setRunOnce] = useState(false);
  const [questions, setQuestions] = useState<string[]>(['Executive Summary']);
  const { projectId, sprintId } = useParams<{ projectId: string; sprintId: string }>();

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useQuery(['canUseAI'], () => canUseAI(), {
    onSuccess: (response: UseAIResult) => setUseAI(response.canUseAI),
    onError: () => console.error('There was an issue determining if the user can use AI'),
  });

  const runAgent = useCallback(
    (question: string) => {
      if (props.isFullData && orgId && useAI && !isActiveQuestion) {
        const agentWebsocket = createAgentWebsocket(orgId as string);
        setIsActiveQuestion(true);
        agentWebsocket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.is_start) {
            setThreadId(data.thread_id);
            setRenderInsights(true);
          }
          if (data.is_update) {
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              updatedMessages[updatedMessages.length - 1] += data.text;
              return updatedMessages;
            });
          }
          if (data.is_done) {
            setIsActiveQuestion(false);
            setMessages((m) => [...m, '']);
            agentWebsocket.close();
          }
        };
        agentWebsocket.addEventListener('open', () => {
          askAgent(agentWebsocket, question, props.context, threadId, projectId as string, sprintId as string);
        });
      }
    },
    [isActiveQuestion, props.context, props.isFullData, threadId, useAI, orgId, projectId, sprintId]
  );

  const autoScroll = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    autoScroll();
  }, [messages, questions]);

  useEffect(() => {
    if (props.isFullData && useAI) {
      if (!runOnce) {
        runAgent(JSON.stringify(props.data));
        setRunOnce(true);
      }
    }
  }, [props.isFullData, props.data, runAgent, runOnce, useAI]);

  const submitQuestion = async () => {
    if (threadId && question) {
      runAgent(question);
      setQuestions([...questions, question]);
      setQuestion('');
    }
  };

  return (
    <div>
      {renderInsights && useAI ? (
        !isShowChat ? (
          <UnstyledButton
            onClick={toggleShowChat}
            style={{
              position: 'fixed',
              bottom: 40,
              right: 30,
              boxShadow: '0px 0px 0px 0px rgba(85, 12, 63, .2)',
              borderRadius: '100px',
              WebkitAnimation: 'pulse 1.5s infinite',
            }}
          >
            <ColoredMark />
          </UnstyledButton>
        ) : (
          <div
            style={{
              position: 'fixed',
              bottom: 40,
              right: 30,
              width: 500,
              height: '75vh',
              maxHeight: 940,
              overflow: 'hidden',
              border: '1px solid black',
              background: addOpacityToColor(newCOLORS.white, 0.95),
              boxShadow: `0px 40px 40px 8px ${addOpacityToColor(newCOLORS.black, 0.5)}`,
              borderRadius: 10,
              padding: 16,
              zIndex: 1000,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <UnstyledButton
                onClick={toggleShowChat}
                style={{
                  fontSize: 21,
                  color: newCOLORS.black,
                  padding: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon icon="fluent:panel-right-contract-24-regular" />
              </UnstyledButton>
            </div>
            <div style={{ flex: 1, overflow: 'auto', marginBottom: 20 }}>
              {questions.map((question, index) => (
                <div key={index} style={{ marginBottom: 16 }}>
                  <Paper
                    shadow="sm"
                    p="md"
                    style={{
                      maxWidth: '80%',
                      marginLeft: 'auto',
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 10,
                      backgroundColor: newCOLORS.purple2,
                      color: 'white',
                    }}
                  >
                    <Text size="md">{question}</Text>
                  </Paper>
                  {messages[index] && (
                    <Paper
                      shadow="sm"
                      p="md"
                      style={{
                        maxWidth: '80%',
                        marginRight: 'auto',
                        marginTop: 8,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                        borderBottomLeftRadius: 0,
                        backgroundColor: newCOLORS.darkestGray,
                      }}
                    >
                      <Markdown>{messages[index]}</Markdown>
                    </Paper>
                  )}
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
            <TextInput
              size="lg"
              style={{
                border: '0px',
              }}
              onChange={(e) => setQuestion(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !isActiveQuestion) {
                  submitQuestion();
                }
              }}
              value={question}
              placeholder="Ask a question about your data...."
              rightSectionWidth={42}
              leftSection={<Icon icon="material-symbols:chat" />}
              rightSection={
                <ActionIcon
                  disabled={isActiveQuestion}
                  size={32}
                  radius="xl"
                  color={newCOLORS.purple2}
                  variant="filled"
                  onClick={submitQuestion}
                >
                  <Icon icon="material-symbols:arrow-right-alt" />
                </ActionIcon>
              }
            />
          </div>
        )
      ) : null}
    </div>
  );
}
