import {
  Measure,
  MeasureDataResponse,
  MeasureValuesOverTime,
} from '../../api/work-periods-client/work-periods-client.type';
import { sortObjectByDateKeys } from '../../containers/process-analysis/process-analysis-chart.helpers';
import { round } from '../math-helpers/math-helpers';

function convertAndRoundMeasureValues(
  measureData: MeasureDataResponse,
  measure: Measure,
  timeBasedMeasures: Measure[],
  precision = 0
): number[] {
  const sortedValues = sortObjectByDateKeys(measureData[measure] ?? {});

  return Object.values(sortedValues).map((value) => {
    if (value !== null && value !== undefined) {
      return timeBasedMeasures.includes(measure) ? round(value / 24, precision) : round(value, 0);
    } else {
      return value;
    }
  });
}

function convertOneMeasure(
  measureData: MeasureValuesOverTime,
  measure: Measure,
  timeBasedMeasures: Measure[],
  precision = 0
): MeasureValuesOverTime {
  if (timeBasedMeasures.includes(measure)) {
    for (const date in measureData) {
      if (measureData[date] !== null && measureData) {
        measureData[date] = round(measureData[date] / 24, precision);
      }
    }
  }

  return measureData;
}

export { convertAndRoundMeasureValues, convertOneMeasure };
