import { styled } from '@linaria/react';
import { Button, Divider } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { icons } from '../../../assets/icons/icons';
import { TeamMultiselect } from '../../../components/multiselect/team-multiselect';
import { newCOLORS } from '../../../styles/colors';
import { useTeamForm } from './scope-explorer.hooks';

export const TeamForm = () => {
  const { teamForm, handleSubmitTeamForm } = useTeamForm();

  return (
    <form onSubmit={teamForm.onSubmit(() => handleSubmitTeamForm(teamForm))}>
      <Panel>
        <TeamMultiselect
          required
          {...teamForm.getInputProps('teams')}
          onChange={(ids) => teamForm.setFieldValue('teams', ids)}
        />
        <Divider orientation="vertical" />
        <DatePickerInput
          required
          leftSection={<img src={icons.iconCalendar} width={16} height={16} />}
          leftSectionPointerEvents="none"
          {...teamForm.getInputProps('startDate')}
          maxDate={teamForm.values.endDate}
          pointer
          miw="fit-content"
        />
        <DatePickerInput
          required
          leftSection={<img src={icons.iconCalendar} width={16} height={16} />}
          leftSectionPointerEvents="none"
          {...teamForm.getInputProps('endDate')}
          minDate={teamForm.values.startDate}
          pointer
          miw="fit-content"
        />
        <Button
          disabled={!teamForm.isDirty() || !teamForm.isValid()}
          color={newCOLORS.darkIndigo}
          type="submit"
          miw={120}
        >
          Apply Filter
        </Button>
      </Panel>
    </form>
  );
};

const Panel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
`;
