import { styled } from '@linaria/react';
import { Button, Divider } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { icons } from '../../../assets/icons/icons';
import { BoardMultiselect } from '../../../components/multiselect/board-multiselect';
import { newCOLORS } from '../../../styles/colors';
import { useBoardForm } from './scope-explorer.hooks';

export const BoardForm = () => {
  const { boardForm, handleSubmitBoardForm } = useBoardForm();

  return (
    <form onSubmit={boardForm.onSubmit(() => handleSubmitBoardForm(boardForm))}>
      <Panel>
        <BoardMultiselect
          required
          {...boardForm.getInputProps('boards')}
          onChange={(boardIds) => boardForm.setFieldValue('boards', boardIds)}
        />
        <Divider orientation="vertical" />
        <DatePickerInput
          required
          leftSection={<img src={icons.iconCalendar} width={16} height={16} />}
          leftSectionPointerEvents="none"
          {...boardForm.getInputProps('startDate')}
          maxDate={boardForm.values.endDate}
          pointer
          miw="fit-content"
        />
        <DatePickerInput
          required
          leftSection={<img src={icons.iconCalendar} width={16} height={16} />}
          leftSectionPointerEvents="none"
          {...boardForm.getInputProps('endDate')}
          minDate={boardForm.values.startDate}
          pointer
          miw="fit-content"
        />
        <Button
          disabled={!boardForm.isDirty() || !boardForm.isValid()}
          color={newCOLORS.darkIndigo}
          type="submit"
          miw={120}
        >
          Apply Filter
        </Button>
      </Panel>
    </form>
  );
};

const Panel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
`;
