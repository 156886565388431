import { ActionIcon, AppShell } from '@mantine/core';
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useBoardSprints } from '../../api/projects-client/projects-client.hooks';
import { GlobalScope } from '../../components/global-scope/global-scope';
import { NavIcon } from '../../components/nav-icon/nav-icon';
import { NavIconType } from '../../components/nav-icon/nav-icon.type';
import { PortfolioContext, ProjectContext, SidebarContext } from '../../contexts';
import { replaceURL } from '../../helpers';
import { secondaryBase } from '../../styles/design-tokens';
import { IntegrationsContext } from '../integrations/context/integrations.context';
import { getIntegrationIndicatorProps } from '../integrations/context/integrations.context.helpers';
import { ROUTES } from '../navigation/navigation';
import { icons } from './assets';
import classes from './side-bar.module.css';
import { GeneralViewNames, NavItem, ViewNames } from './side-bar.type';

const isGlobalScopeEnabled = import.meta.env.VITE_FEATURE_FLAG_GLOBAL_ORG_PORTFOLIO === 'true';

export function SideBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { project, setProject } = useContext(ProjectContext);
  const { portfolio } = useContext(PortfolioContext);
  const { subproject_sprints } = useBoardSprints(project?.id, { enabled: !!project });
  const { state: integrationState } = useContext(IntegrationsContext);
  const { windowViewportWidth } = useContext(SidebarContext);
  const { sidebarOpen, setSidebarOpen, navItems, setNavItems } = useContext(SidebarContext);
  const [activeView, setActiveView] = useState<GeneralViewNames | ViewNames | null>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (windowViewportWidth < 992) {
      setSidebarOpen(false);
    }
  }, [windowViewportWidth, setSidebarOpen]);

  useEffect(() => {
    if (project) {
      const newNavItems: NavItem[] = navItems.map((item: NavItem) => {
        const navItem: NavItem = { ...item };
        if (!navItem.general) {
          navItem.path = replaceURL(navItem.path, project.id.toString());
          navItem.show = true;
        }
        return navItem;
      });
      setNavItems(newNavItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    const activeViewRoute = ROUTES.find((route) => matchPath(`/application/${route.path}`, location.pathname));
    if (activeViewRoute && activeViewRoute.view) {
      // check if we are viewing the sprint-assessment and highlight
      // the history tab in the sidebar if we are not viewing the current sprint
      if (project && location.pathname.includes('sprint-assessment')) {
        const subprojects = Object.keys(subproject_sprints || {});
        // TODO: this simply gets the first subproject in the list. We need to handle the subproject selector
        const sprints =
          subproject_sprints && subprojects.length && subprojects[0] in subproject_sprints
            ? subproject_sprints[subprojects[0]]
            : [];
        if (sprints.length && !location.pathname.includes(sprints[0][0])) {
          setActiveView(ViewNames.History);
        } else {
          setActiveView(activeViewRoute.view);
        }
      } else {
        setActiveView(activeViewRoute.view);
      }
    }
  }, [location.pathname, project, subproject_sprints]);

  const getIndicatorProps = useCallback(
    (navItem: NavItem) => {
      if (navItem.label === GeneralViewNames.Integrations && integrationState) {
        return getIntegrationIndicatorProps(integrationState);
      }

      return null;
    },
    [integrationState]
  );

  const onNavItemClicked = (event: React.MouseEvent, clickedItem: NavItem) => {
    event.preventDefault();
    navigate(clickedItem.path);
    if (clickedItem.general) {
      setProject(null);
      const newNavItems: NavItem[] = navItems.map((item: NavItem) => {
        const navItem: NavItem = { ...item };
        if (!navItem.general) {
          navItem.show = false;
        }
        return navItem;
      });
      setNavItems(newNavItems);
    }
  };

  const sidebarWidthBase = isGlobalScopeEnabled ? 70 : 100;

  return (
    <AppShell.Navbar
      w={{ sm: sidebarOpen ? 280 : sidebarWidthBase, base: sidebarWidthBase }}
      p="md"
      zIndex={0}
      style={{ boxSizing: 'border-box' }}
    >
      {windowViewportWidth >= 992 && !isGlobalScopeEnabled && (
        <AppShell.Section>
          {sidebarOpen ? (
            <div className={classes.sideBarHeader}>
              <div ref={textRef} className={classes.orgLabel}>
                {portfolio?.organization?.name || ''}
              </div>
              <div
                data-testid="sidebar-toggle"
                className={classes.link}
                onClick={() => setSidebarOpen((prevSidebarOpen) => !prevSidebarOpen)}
              >
                <NavIcon iconType={NavIconType.iconDoubleArrowLeft} label={''} hide={true} sidebarOpen={sidebarOpen} />
              </div>
            </div>
          ) : (
            <div
              data-testid="sidebar-toggle"
              className={classes.link}
              onClick={() => setSidebarOpen((prevSidebarOpen) => !prevSidebarOpen)}
            >
              <NavIcon iconType={NavIconType.iconDoubleArrowRight} label={''} hide={true} sidebarOpen={sidebarOpen} />
            </div>
          )}
        </AppShell.Section>
      )}
      {isGlobalScopeEnabled && (
        <AppShell.Section style={{ paddingBottom: 16 }}>
          <GlobalScope enableTooltips={!sidebarOpen} />
        </AppShell.Section>
      )}
      <AppShell.Section grow>
        {navItems &&
          navItems.map(
            (item) =>
              item.show && (
                <a
                  data-active={item.label === activeView}
                  className={item.label === activeView ? classes.linkActive : classes.link}
                  href={item.path}
                  key={item.label}
                  onClick={(event) => onNavItemClicked(event, item)}
                >
                  {sidebarOpen ? (
                    <Fragment>
                      <NavIcon
                        iconType={item.icon}
                        label={item.label}
                        hide={true}
                        indicatorProps={getIndicatorProps(item)}
                        sidebarOpen={sidebarOpen}
                      />
                      <span
                        style={{
                          marginLeft:
                            item.icon === NavIconType.iconPortfolio ||
                            item.icon === NavIconType.iconProject ||
                            item.icon === NavIconType.iconCurrentSprint ||
                            item.icon === NavIconType.iconHistory
                              ? '1.25em'
                              : '1em',
                        }}
                      >
                        {item.label}
                      </span>
                      {item.label === GeneralViewNames.ProcessAnalysis && (
                        <NavIcon iconType={NavIconType.iconBeta} label={''} hide={true} sidebarOpen={sidebarOpen} />
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <NavIcon
                        iconType={item.icon}
                        label={item.label}
                        hide={false}
                        indicatorProps={!isGlobalScopeEnabled ? getIndicatorProps(item) : undefined}
                        sidebarOpen={sidebarOpen}
                      />
                      {!isGlobalScopeEnabled &&
                        (item.label === GeneralViewNames.ProcessAnalysis ||
                          item.label === GeneralViewNames.Process) && (
                          <NavIcon iconType={NavIconType.iconBeta} label={''} hide={true} sidebarOpen={sidebarOpen} />
                        )}
                    </Fragment>
                  )}
                </a>
              )
          )}
      </AppShell.Section>
      {isGlobalScopeEnabled && (
        <ActionIcon
          variant="filled"
          color={secondaryBase}
          size={36}
          radius="xl"
          style={{ position: 'absolute', left: 16, bottom: 16 }}
          onClick={() => setSidebarOpen((prevSidebarOpen) => !prevSidebarOpen)}
          data-testid="sidebar-toggle"
        >
          <img src={sidebarOpen ? icons.iconSidebarClose : icons.iconSidebarOpen} width={14} height={14} />
        </ActionIcon>
      )}
    </AppShell.Navbar>
  );
}
