import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import { useGlobalStore } from '../../store/global-store/global-store';

export function BoardMultiselect(props: Omit<MultiSelectProps, 'data'>) {
  const { value: boardIds, onChange: handleBoardSelected, ...rest } = props;
  const teams = useGlobalStore((state) => state.teams);

  useEffect(() => {
    if (teams && teams?.length && teams[0].subprojects[0] && boardIds && !boardIds?.length) {
      handleBoardSelected && handleBoardSelected([teams[0].subprojects[0].id]); // updates dropdown value
    }
  }, [teams, boardIds, handleBoardSelected]);

  function onBoardSelected(boardIds: string[]) {
    handleBoardSelected && handleBoardSelected(boardIds);
  }

  const getOptions = useCallback(() => {
    return (teams || []).map((team, index) => ({
      group: `${index + 1}-${team.name}`,
      items: team.subprojects
        .filter((subproject) => subproject.id && subproject.name) // Check that both id and name exist
        .map((subproject) => ({
          value: subproject.id,
          label: subproject.name,
        })),
    }));
  }, [teams]);

  return (
    <MultiSelect {...rest} value={boardIds} data={getOptions()} onChange={onBoardSelected} style={{ minWidth: 200 }} />
  );
}
