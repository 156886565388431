import { ChartDataset, Point } from 'chart.js';
import { newCOLORS } from '../../styles/colors';

/**
 * Helper method to format the data for the line chart
 *
 * @param data data to be plotted
 * @param color color of the line
 * @param trendLineOrNot boolean to check if the line is a trend line or not
 * @returns an object with the formatted data
 */
const createLine = (
  measureName: string,
  data: number[],
  color: string,
  trendLineOrNot: boolean,
  yAxisID?: 'y' | 'y1'
): ChartDataset<'line', (number | Point | null)[]> => {
  return {
    data: data,
    label: measureName,
    borderColor: color,
    backgroundColor: color,
    pointBackgroundColor: newCOLORS.white,
    pointHitRadius: 5,
    borderDash: trendLineOrNot ? [10, 5] : [],
    pointHoverRadius: 0,
    borderWidth: 2,
    tension: 0.1,
    yAxisID: yAxisID ? yAxisID : 'y',
  };
};

/**
 * Sort the response object by date keys
 *
 * @param obj a response object
 * @returns a sorted object by date keys
 */
const sortObjectByDateKeys = (obj: { [key: string]: number }): { [key: string]: number } => {
  return Object.keys(obj)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    .reduce<{ [key: string]: number }>((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};

export { createLine, sortObjectByDateKeys };
