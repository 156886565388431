import dayjs from 'dayjs';
import { useWorkPeriodsMeasureSingular } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  HealthScoreData,
  Measure,
  Transformer,
  UseWorkPeriodsMeasurePayloadSingular,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDateRange, useHistoricalBurns } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { WorkPeriodType } from '../process-analysis.type';
import { FlowData, KeyProcessMeasuresData } from './assessment-view.type';

/**
 * Custom hook to fetch data for the assessment view.
 *
 * @returns {object} - An object containing the score data, key measures data, flow data and an isLoading flag.
 * The data is fetched from the API and is only returned if the fetching is completed.
 * The isLoading flag is true if any of the data is being fetched.
 */
const useAssessmentViewData = (): {
  scoreData: HealthScoreData | undefined;
  keyMeasuresData: KeyProcessMeasuresData | undefined;
  flowData: FlowData | undefined;
  isLoading: boolean;
} => {
  const { data: scoreData, isFetching: isFetchingScoreData } = useSprintPerformanceScoreData();
  const { data: keyMeasuresData, isFetching: isFetchingKeyMeasuresData } = useKeyProcessMeasuresData();
  const { data: flowData, isFetching: isFetchingFlowData } = useFlowData();

  const isLoading = isFetchingScoreData || isFetchingKeyMeasuresData || isFetchingFlowData;

  return {
    scoreData,
    keyMeasuresData,
    flowData,
    isLoading,
  };
};

/**
 * Custom hook to fetch the sprint performance score data.
 *
 * @returns {object} - An object containing the fetched data and an isLoading flag.
 * The data is fetched from the API and is only returned if the fetching is completed.
 * The isLoading flag is true if the data is being fetched.
 */
const useSprintPerformanceScoreData = (): { data: HealthScoreData | undefined; isFetching: boolean } => {
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);

  const payload = usePayload([Measure.HealthScores]);
  const { data, query } = useWorkPeriodsMeasureSingular<HealthScoreData>(
    { ...payload, transformer: Transformer.SingleLastNonNull },
    { enabled: !!workPeriodBoardId }
  );

  return { data, isFetching: query.isFetching };
};

/**
 * Custom hook to fetch the key process measures data.
 *
 * @returns {object} - An object containing the fetched data and an isLoading flag.
 * The data is fetched from the API and is only returned if the fetching is completed.
 * The isLoading flag is true if the data is being fetched.
 */
const useKeyProcessMeasuresData = (): { data: KeyProcessMeasuresData; isFetching: boolean } => {
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);

  const defaultPayload = usePayload([Measure.Readiness]);
  const { data: defaultData, query: defaultQuery } = useWorkPeriodsMeasureSingular<Partial<KeyProcessMeasuresData>>(
    defaultPayload,
    {
      enabled: !!workPeriodBoardId,
    }
  );

  const singleTransformerPayload = usePayload([
    Measure.Strategy,
    Measure.Complexity,
    Measure.Quality,
    Measure.Independence,
    Measure.ReviewTime,
    Measure.DeclinedChangeRequests,
  ]);
  const { data: singleTransformerData, query: singleTransformerQuery } = useWorkPeriodsMeasureSingular<
    Partial<KeyProcessMeasuresData>
  >(
    { ...singleTransformerPayload, transformer: Transformer.SingleFractionalValue },
    {
      enabled: !!workPeriodBoardId,
    }
  );

  const cumulativeTransformerPayload = usePayload([Measure.ScopeCreep]);
  const { data: cumulativeTransformerData, query: cumulativeTransformerQuery } = useWorkPeriodsMeasureSingular<
    Partial<KeyProcessMeasuresData>
  >(
    { ...cumulativeTransformerPayload, transformer: Transformer.CumulativeSingularValue },
    {
      enabled: !!workPeriodBoardId,
    }
  );

  const isFetching =
    defaultQuery.isFetching || singleTransformerQuery.isFetching || cumulativeTransformerQuery.isFetching;
  const data = { ...defaultData, ...singleTransformerData, ...cumulativeTransformerData } as KeyProcessMeasuresData;

  return { data, isFetching };
};

/**
 * Custom hook to fetch the flow data.
 *
 * @returns {object} - An object containing the fetched data and an isLoading flag.
 * The data is fetched from the API and is only returned if the fetching is completed.
 * The isLoading flag is true if the data is being fetched.
 */
const useFlowData = (): { data: FlowData | undefined; isFetching: boolean } => {
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);
  const showHistoricalBurns = useHistoricalBurns();

  const measures = [Measure.FlowByPhase, Measure.FlowByPace, Measure.Burns];

  if (showHistoricalBurns) {
    measures.push(Measure.HistoricalBurns);
  }

  const payload = usePayload(measures);
  const { data, query } = useWorkPeriodsMeasureSingular<FlowData>(payload, { enabled: !!workPeriodBoardId });

  return { data, isFetching: query.isFetching };
};

/**
 * Custom hook to create the payload for fetching measures data.
 * The payload depends on the work period type.
 * If the work period type is predefined (e.g. sprint, increment etc),
 * the payload contains the work period ID and its start and end dates.
 * Otherwise, the payload contains the start and end dates of the custom period date range.
 *
 * @param {Measure[]} - An array of measures to be fetched.
 * @returns {UseWorkPeriodsMeasurePayloadSingular} - The payload for fetching measures data.
 */
const usePayload = (measures: Measure[]): UseWorkPeriodsMeasurePayloadSingular => {
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);
  const { startDate, endDate } = useDateRange();

  const defaultPayload = {
    subproject_id: workPeriodBoardId,
    measure_name: measures,
  };

  const customPayload =
    workPeriodType === WorkPeriodType.Defined
      ? {
          work_period_id: workPeriod?.id,
          start_date: dayjs(workPeriod?.start_date).format('YYYY-MM-DD'),
          end_date: dayjs(workPeriod?.end_date).format('YYYY-MM-DD'),
        }
      : {
          start_date: dayjs(startDate).format('YYYY-MM-DD'),
          end_date: dayjs(endDate).format('YYYY-MM-DD'),
        };

  const payload = {
    ...defaultPayload,
    ...customPayload,
  } as UseWorkPeriodsMeasurePayloadSingular;

  return payload;
};

export { useAssessmentViewData };
