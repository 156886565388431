import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import {
  AISprintSummary,
  AgingWIPResponse,
  ChangeRequest,
  JiraHistoryResponse,
  Sprint,
  SprintAuditLogEntry,
  SprintBurnsAndFlows,
  SprintMeasure,
  SprintMeasureData,
  SprintMetrics,
  SprintProjections,
  SubprojectSprints,
} from './sprints-client.type';

/**
 * This file alone uses headers explicity when the request is being
 * formed to prevent the 403 forbidden error
 */

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;

/**
 * Fetches sprint object for a given sprint id.
 * @param sprintId - sprint id
 * @returns Sprint
 */
const fetchSprint = async (sprintId: string): Promise<Sprint> => {
  const apiURL = baseURL + `/api/sprints/${sprintId}/`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error.response || 'There was a problem fetching the sprint details');
    });
};

/**
 * Fetches sprint burns and flows for a given sprint id.
 * @param sprintId - sprint id
 * @returns dict containing burns and flows
 */
const fetchSprintBurnsAndFlows = async (sprintId: string): Promise<SprintBurnsAndFlows> => {
  const apiURL = baseURL + `/api/sprints/${sprintId}/burns-and-flows/`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error.response || 'There was a problem fetching the sprint details');
    });
};

/**
 * Fetches sprint metrics for a given sprint id.
 * @param sprintId - sprint id
 * @returns dict containing metrics
 */
const fetchSprintMetrics = async (sprintId: string): Promise<SprintMetrics> => {
  const apiURL = baseURL + `/api/sprints/${sprintId}/metrics/`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error.response || 'There was a problem fetching the sprint details');
    });
};

/**
 * Fetches sprint summary for a given sprint id.
 * @param sprintId - sprint id
 * @returns Sprint
 */
const fetchSprintSummary = async (sprintId: string): Promise<AISprintSummary> => {
  const apiURL = baseURL + `/api/sprints/${sprintId}/summary/`;
  return await axiosInstance
    .get<AISprintSummary>(apiURL)
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error.response || 'There was a problem fetching the sprint summary');
    });
};

/**
 *  Fetches sprint projections for a given sprint id.
 * @param sprintId - sprint id
 * @returns Sprint
 */
const fetchSprintProjections = async (sprintId: string): Promise<SprintProjections> => {
  const apiURL = baseURL + `/api/sprints/${sprintId}/projections/`;
  return await axiosInstance
    .get<SprintProjections>(apiURL)
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error.response || 'There was a problem fetching the sprint projections');
    });
};

/**
 * Fetches Aging WIP data for a given sprint ID.
 * @param sprintID ID of the project's current_sprint object to get the latest Aging WIP info for
 * @returns AgingWIPResponse object.
 */
const fetchAgingWIP = async (sprintID: string) => {
  const apiURL = baseURL + `/api/sprints/${sprintID}/aging_wip/`;
  return await axiosInstance
    .get<AgingWIPResponse>(apiURL)
    .then((response) => response.data)
    .catch((error) => {
      return error.response;
    });
};

/**
 *  Fetches sprint projections for a given sprint id.
 * @param sprintId - sprint id
 */
const fetchSprintMeasures = async (sprintId: string, type: string): Promise<SprintMeasureData> => {
  const apiURL = baseURL + `/api/sprints/${sprintId}/measures/${type}/`;
  return await axiosInstance
    .get<SprintMeasure>(apiURL)
    .then((response) => response.data.measure)
    .catch((error) => {
      return Promise.reject(error.response || 'There was a problem fetching the sprint measures');
    });
};

/**
 * Fetches sprint github measures for a given sprint ID.
 * @param sprintId Sprint ID to get the measures for
 * @param type Type of measure to get
 * @returns Array of ChangeRequest objects that contributes to that given measure
 */
const fetchSprintGithubMeasures = async (sprintId: string, type: string): Promise<ChangeRequest[]> => {
  const apiURL = baseURL + `/api/sprints/${sprintId}/github-measures/${type}/`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error.response || 'There was a problem fetching the sprint measures');
    });
};

/**
 * Fetches sprint audit log for a given sprint ID.
 * @param sprintId Sprint ID to get the measures for
 * @returns Array of SprintAuditLogEntry objects for the sprint
 */
const fetchSprintAuditLog = async (sprintId: string): Promise<SprintAuditLogEntry[]> => {
  const apiURL = baseURL + `/api/sprints/${sprintId}/audit-log/`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response || 'There was a problem fetching the sprint audit log'));
};

/**
 * Fetches sprint history by date for a given sprint ID.
 * @param sprintId Sprint ID to get the measures for
 * @returns JiraHistoryResponse objects for the sprint
 */
const fetchSprintJiraHistory = async (sprintId: string): Promise<JiraHistoryResponse> => {
  const url = baseURL + `/api/sprints/${sprintId}/jira-history/`;
  return axiosInstance
    .get(url.toString())
    .then(({ data }) => Promise.resolve(data))
    .catch((error) => Promise.reject(error.response || 'Error fetching jira history'));
};

async function fetchSubprojectSprints(subprojectId: string): Promise<SubprojectSprints> {
  const url = baseURL + `/api/subprojects/${subprojectId}/sprints/`;

  return axiosInstance
    .get(url.toString())
    .then(({ data }) => Promise.resolve(data))
    .catch((error) => Promise.reject(error.response || 'Error fetching sprints for subproject'));
}

export {
  fetchAgingWIP,
  fetchSprint,
  fetchSprintAuditLog,
  fetchSprintBurnsAndFlows,
  fetchSprintGithubMeasures,
  fetchSprintJiraHistory,
  fetchSprintMeasures,
  fetchSprintMetrics,
  fetchSprintProjections,
  fetchSprintSummary,
  fetchSubprojectSprints,
};
