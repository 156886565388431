import dayjs from 'dayjs';
import {
  Measure,
  MeasureDataResponse,
  MeasureValuesOverTime,
  TimeAllocation,
} from '../../../api/work-periods-client/work-periods-client.type';
import { MEASURE_COLOR_MAP } from '../process-analysis.helpers';
import { MeasuresWithColor } from './measure-comparison.type';

// a list of the measures which use the Y axis in the measure comparison chart
const measureYAxisList = [
  'consistent_commitments',
  'cycle_time_consistency',
  'multiple_point_changes_per_task',
  'points_acceptance_rate',
  'sprint_consistency',
  'tasks_acceptance_rate',
  'tasks_moving_backwards',
  'tasks_untested',
];

/** determines whether the Y axis is in use
 *
 * @param measureNames - a list of measure names
 * @param trendNames - a list of trend names
 * @returns boolean - whether the Y axis is in use
 */
function yAxisInUse(measureNames: string[], trendNames: string[]): boolean {
  return (
    measureNames.some((name) => measureYAxisList.includes(name)) ||
    trendNames.some((name) => measureYAxisList.includes(name))
  );
}

/** determines whether the Y1 axis is in use
 *
 * @param measureNames - a list of measure names
 * @param trendNames - a list of trend names
 * @returns boolean - whether the Y1 axis is in use
 */
function y1AxisInUse(measureNames: string[], trendNames: string[]): boolean {
  return (
    (!!measureNames.length && measureNames.some((name) => !measureYAxisList.includes(name))) ||
    (!!trendNames.length && trendNames.some((name) => !measureYAxisList.includes(name)))
  );
}

/**
 * Calculates the average value of the given monthly data.
 *
 * @param {MeasureValuesOverTime} data - The data to calculate the average from.
 * @return {number | null} The average value of the data. Returns null if the data is empty or all values are null.
 */
const calculateAverage = (data: MeasureValuesOverTime): number | null => {
  const keys = Object.keys(data);

  if (!keys.length || keys.every((key) => data[key] === null)) {
    return null;
  }

  return Object.values(data).reduce((acc, value) => acc + value, 0) / keys.length;
};

/**
 * Returns an array of month labels between the given start and end dates.
 *
 * @param {Date} startDate - The start date.
 * @param {Date} endDate - The end date.
 * @return {string[]} An array of month labels in the format 'MMM YY'.
 */
const getMonthLabels = (startDate: Date, endDate: Date): string[] => {
  const labels = [];
  let current = dayjs(startDate);

  while (current.isBefore(endDate)) {
    labels.push(current.format('MMM YY'));
    current = current.add(1, 'month');
  }

  return labels;
};

/**
 * Returns an array of chart labels based on the provided measure values and time allocation.
 *
 * @param {MeasureDataResponse} measureData - The measure data to be transformed.
 * @param {TimeAllocation} timeAllocation - The time allocation for the chart labels.
 * @return {string[]} An array of chart labels in the format determined by the time allocation.
 */
const getChartLabels = (measureData: MeasureDataResponse, timeAllocation: TimeAllocation): string[] => {
  const measures = Object.keys(measureData) as Measure[];
  const defaultMeasure = measures.length > 0 ? measures.find((measure) => measureData[measure] !== null) : null;

  if (!defaultMeasure) {
    return [];
  }

  const measureValues = measureData[defaultMeasure];

  const rawLabels = Object.keys(measureValues)
    .map(dayjs)
    .sort((a, b) => a.diff(b));

  const customFormat = timeAllocation === TimeAllocation.Monthly ? 'YYYY MMM' : 'YYYY MMM D';

  return rawLabels.map((label) => label.format(customFormat).toLocaleUpperCase());
};

/**
 * Returns an object mapping measure names to their corresponding colors.
 *
 * @param {Measure[]} measures - An array of measure names.
 * @return {MeasuresWithColor} An object containing measure names as keys and their corresponding colors as values.
 */
const getMeasuresWithColors = (measures: Measure[]): MeasuresWithColor => {
  return measures.reduce((acc, curr) => {
    if (curr in MEASURE_COLOR_MAP) {
      acc[curr as keyof typeof acc] = MEASURE_COLOR_MAP[curr as keyof typeof MEASURE_COLOR_MAP];
    }
    return acc;
  }, {} as MeasuresWithColor);
};

/**
 * Sorts the given measure data by date in ascending order.
 *
 * @param {MeasureValuesOverTime} data - The measure data to be sorted.
 * @return {MeasureValuesOverTime} The sorted measure data.
 */
const sortMeasureDataOverTime = (data: MeasureValuesOverTime): MeasureValuesOverTime => {
  const sortedLabels = Object.keys(data).sort((a, b) => dayjs(a).diff(dayjs(b)));

  return sortedLabels.reduce((acc, label) => {
    acc[label] = data[label];

    return acc;
  }, {} as MeasureValuesOverTime);
};

/**
 * Returns an array of default measures for the measure comparison view.
 *
 * @return {Measure[]} An array of default measures.
 */
const getDefaultMeasures = (): Measure[] => {
  return [Measure.Throughput, Measure.Velocity, Measure.LeadTime, Measure.ReactionTime, Measure.CycleTime];
};

export {
  calculateAverage,
  getChartLabels,
  getDefaultMeasures,
  getMeasuresWithColors,
  getMonthLabels,
  measureYAxisList,
  sortMeasureDataOverTime,
  y1AxisInUse,
  yAxisInUse,
};
