import { ProjectsResponse } from '../projects-client/projects-client.type';
import { Epic, MinifiedTask } from '../tasks-client/task-client.type';

enum RelativeBusinessValueUnits {
  numeric = 'numeric',
  dollars = 'dollars',
  none = 'none',
}

enum InitiativeStatus {
  OnTrack = 'on_track',
  AtRisk = 'at_risk',
  OffTrack = 'off_track',
}

enum InitiativeSource {
  Bloomfilter = 'bloomfilter',
  Jira = 'jira',
  JDC = 'jira_data_center',
  Github = 'github',
  ADO = 'ado',
}

enum InitiativeSourceStatus {
  Created = 'created',
  Updated = 'updated',
  Deleted = 'deleted',
}

type Metric = {
  tasks: Record<string, number | null>;
  points: Record<string, number | null>;
  tasks_added: Record<string, number | null>;
  bugs: Record<string, number | null>;
};

type InitiativeStatusData = {
  status: InitiativeStatus;
  percent_time_elapsed: number;
  percent_subtasks_complete: number;
  by_tasks?: {
    status: InitiativeStatus;
    percent_time_elapsed: number;
    percent_subtasks_complete: number;
  };
  by_points?: {
    status: InitiativeStatus;
    percent_time_elapsed: number;
    percent_subtasks_complete: number;
  };
};

type Initiative = {
  id: string;
  name: string;
  description: string;
  source: InitiativeSource;
  source_status: InitiativeSourceStatus | null;
  start_date?: string;
  end_date: string;
  relative_business_value?: number | null;
  relative_business_value_unit?: RelativeBusinessValueUnits;
  portfolio: string;
  status?: InitiativeStatusData;
  epics?: Epic[];
  projects?: ProjectsResponse[];
  percent_elapsed: number;
  percent_progress: number;
};

type InitialContributions = {
  [id: string]: string | null;
};

type CostsToDate = {
  initiative: number;
  epics: {
    [id: string]: number;
  };
  teams: {
    [id: string]: number;
  };
};

type ExtendedInitiative = Initiative & {
  source_url: string | null;
  projects: ProjectsResponse[];
  epics: Epic[];
  total_subtasks_over_time: Metric;
  completed_subtasks_over_time: Metric;
  contributing_epics_over_time: Record<string, Metric>;
  contributing_teams_over_time: Record<string, Metric>;
  initial_contributions: InitialContributions;
  costs_to_date: CostsToDate;
  metadata_created_at: string;
  metadata_created_by: string | null;
  metadata_updated_at: string;
  metadata_modified_by: string | null;
  contains_active_epics_after_close: boolean;
};

type FocusData = {
  focus_by_team_over_time: Record<string, Metric>;
};

type PreSaveInitiative = {
  id?: string;
  name: string;
  description: string;
  source: InitiativeSource;
  start_date?: string;
  end_date: string;
  relative_business_value?: number | null;
  relative_business_value_unit?: RelativeBusinessValueUnits;
  portfolio: string;
  projects?: string[];
  epics?: string[];
};

type InitiativeTasksConfig = {
  portfolioId: string | undefined;
  initiativeId: string | undefined;
  filter: string | null;
  date: string | null;
  epicId: string | null;
  projectId: string | null;
};

type InitiativeTaskResponse = {
  name: string;
  tasks: MinifiedTask[];
};

export type {
  CostsToDate,
  ExtendedInitiative,
  FocusData,
  Initiative,
  InitiativeTaskResponse,
  InitiativeTasksConfig,
  Metric,
  PreSaveInitiative,
};

export { InitiativeSource, InitiativeSourceStatus, InitiativeStatus, RelativeBusinessValueUnits };
