import { Select } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { Fragment, forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Team } from '../../api/projects-client/projects-client.type';
import { fetchPortfolioProjects } from '../../api/summary-client/summary-client';
import { PortfolioContext, TeamContext } from '../../contexts';
import { StandardText } from '../../styles/new-shared-styled-components/new-shared-styled-components';

export type TeamSelectRef = {
  getTeams: () => Team[];
};

export type TeamSelectProps = {
  handleTeamSelected?: (team: Team) => void;
  hideLabel?: boolean;
  includeAggregate?: boolean;
};

export const TeamSelect = forwardRef<TeamSelectRef, TeamSelectProps>(
  ({ hideLabel, handleTeamSelected, includeAggregate }, ref) => {
    const navigate = useNavigate();
    const { portfolio } = useContext(PortfolioContext);
    const { team, setTeam } = useContext(TeamContext);
    const [teams, setTeams] = useState([] as Team[]);

    useQuery(['fetchPortfolioProjects', portfolio], () => portfolio && fetchPortfolioProjects(portfolio?.id), {
      enabled: !!portfolio,
      onSuccess: (data) => {
        const dataTeams = data as Team[];
        if (includeAggregate) {
          dataTeams.unshift({ id: 'aggregate', name: 'Portfolio (All Teams)' });
        }
        setTeams(dataTeams);
        if (dataTeams.length > 0 && (!team || !dataTeams.find((tm) => tm.id == team.id))) {
          setTeam(dataTeams[0]);
        }
      },
      onError: (error: any) => {
        const errorState = { errorMessage: error?.statusText, errorStatus: error?.status };
        navigate(`/application/out-of-bloom`, { state: errorState });
      },
    });

    useImperativeHandle(ref, () => ({
      getTeams: () => {
        return teams;
      },
    }));

    const teamSelected = (team: Team) => {
      setTeam(team);
      handleTeamSelected && handleTeamSelected(team);
    };

    const refactorDropdownData = (teams: Team[]) => {
      const refactoredPortfolios = teams.map((tm) => {
        return { value: tm.id, label: tm.name };
      });
      return refactoredPortfolios;
    };

    return (
      <Fragment>
        {!hideLabel && <StandardText style={{ marginLeft: 16, marginRight: 8 }}>Team</StandardText>}
        <Select
          data={refactorDropdownData(teams)}
          value={team?.id}
          onChange={(value) => {
            teamSelected(teams.find((tm) => tm.id == value) as Team);
          }}
          allowDeselect={false}
        />
      </Fragment>
    );
  }
);
