import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { Fragment, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchSprint, fetchSprintJiraHistory } from '../../api/sprints-client/sprints-client';
import { JiraHistoryResponse, JiraHistoryType, Sprint } from '../../api/sprints-client/sprints-client.type';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb';
import { CrumbItem } from '../../components/breadcrumb/breadcrumb.type';
import { JiraBoard } from '../../components/jira-history/jira-history-board';
import { ProjectContext, SidebarContext } from '../../contexts';
import { newCOLORS } from '../../styles/colors';
import { BreadcrumbContainer, OuterPaddingDiv, TitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { onPortfolioBreadcrumbClick } from '../side-bar/side-bar.helper';

export const JiraHistory = () => {
  const navigate = useNavigate();
  const { projectId, sprintId } = useParams<{ projectId: string; sprintId: string }>();
  const [sprint, setSprint] = useState<Sprint | null>(null);
  const [jiraHistory, setJiraHistory] = useState<JiraHistoryType | null>(null);
  const { project, setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);

  const breadCrumbitems: CrumbItem[] = [
    {
      labelName: 'Portfolio',
      href: '/application/dashboard',
      onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
    },
    { labelName: project?.name as string, href: `/application/project/${projectId}` },
    {
      labelName: sprint?.name as string,
      href: `/application/project/${projectId}/sprint-assessment/${sprintId}`,
    },
    { labelName: 'Task Flow' },
  ];

  useQuery(
    ['sprint', sprintId],
    () => (sprintId ? fetchSprint(sprintId) : Promise.reject('Unable to resolve SprintId or SprintID is missing')),
    {
      enabled: !!sprintId,
      onSuccess: setSprint,
      onError: () => console.error('There was an issue fetching sprint data.'),
    }
  );

  useQuery(['fetchSprintJiraHistory', sprintId], () => fetchSprintJiraHistory(sprintId as string), {
    enabled: !!sprintId,
    onSuccess: (data: JiraHistoryResponse) => {
      setJiraHistory(data.jira_history);
    },
    onError: (error: any) => {
      const errorState = { errorMessage: error?.statusText, errorStatus: error?.status };
      navigate(`/application/out-of-bloom`, { state: errorState });
    },
  });

  return (
    <Fragment>
      <MenuDrivenContainer
        contentStyles={{ backgroundColor: newCOLORS.lighterGray }}
        containerStyles={{ backgroundColor: newCOLORS.lighterGray }}
      >
        <OuterPaddingDiv>
          <JiraHistoryContainer>
            <BreadcrumbContainer data-testid="breadcrumb">
              <Breadcrumb crumbItems={breadCrumbitems} />
            </BreadcrumbContainer>

            <JiraHistoryHeader>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TitleHeadingTag>Task Flow</TitleHeadingTag>
              </div>
            </JiraHistoryHeader>
            <div>
              {jiraHistory && sprint && (
                <JiraBoard
                  history={jiraHistory}
                  startDate={new Date(sprint.start_date)}
                  endDate={new Date(sprint.end_date)}
                />
              )}
            </div>
          </JiraHistoryContainer>
        </OuterPaddingDiv>
      </MenuDrivenContainer>
    </Fragment>
  );
};

const JiraHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const JiraHistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
