import {
  ActiveElement,
  BarElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useRef } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { Metric } from '../../../../api/initiative-client/initiative-client.type';
import { Epic } from '../../../../api/tasks-client/task-client.type';
import { extractYearMonth } from '../../../../helpers/string-helpers/string-helpers';
import { getDateLabels } from '../initiative-performance.helpers';
import { ColoredEntity, InitiativeMetricType } from '../initiative-performance.type';
import { getChartTitle, getYAxisLabel } from './section-epics.helpers';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const getData = (
  epics: Epic[],
  metric: InitiativeMetricType,
  rawData: Record<string, Metric>,
  coloredEpics: ColoredEntity[]
) => {
  const firstContributingEpicData = Object.values(rawData)[0] || {};
  const labels = getDateLabels(Object.keys(firstContributingEpicData.tasks || {}));

  return {
    labels,
    datasets: Object.keys(rawData).map((epicId) => {
      const color = coloredEpics.find((entity) => entity.id === epicId)?.color;
      const url = epics.find((epic) => epic.id === epicId)?.url;
      return {
        label: epicId,
        url,
        data: Object.values(rawData[epicId]?.[metric] || {}),
        borderColor: color,
        backgroundColor: color,
      };
    }),
  };
};

const getOptions = (metric: InitiativeMetricType): any => ({
  responsive: true,
  maintainAspectRatio: false,
  pointStyle: true,
  cubicInterpolationMode: 'default',
  borderWidth: 1,
  barThickness: 39,
  onHover: (event: ChartEvent, elements: ActiveElement[]) => {
    let cursorStyle = 'default';

    const isOnElement = elements.length > 0;
    if (isOnElement && [InitiativeMetricType.Tasks, InitiativeMetricType.Points].includes(metric)) {
      cursorStyle = 'pointer';
    }
    if (event?.native?.target) {
      (event.native.target as HTMLElement).style.cursor = cursorStyle;
    }
  },
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        text: 'Month',
        font: {
          size: 12,
        },
      },
      ticks: {
        font: {
          size: 12,
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      beginAtZero: true,
      position: 'left' as const,
      title: {
        display: true,
        text: getYAxisLabel(metric),
        font: {
          size: 12,
        },
        padding: {
          bottom: 0,
        },
      },
      ticks: {
        font: {
          size: 12,
        },
        padding: 10,
      },
      border: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: getChartTitle(metric),
      font: {
        size: 16,
        weight: 500,
      },
      padding: {
        bottom: 20,
      },
    },
    annotation: {
      common: {
        drawTime: 'afterDraw',
      },
    },
    tooltip: {
      enabled: false,
    },
    filler: {
      propagate: true,
      drawTime: 'beforeDatasetsDraw' as const,
    },
  },
});

type Props = {
  epics: Epic[];
  metric: InitiativeMetricType;
  contributingEpicsData: Record<string, Metric>;
  coloredEpics: ColoredEntity[];
};

export function SectionEpicsChart({ epics, metric, contributingEpicsData, coloredEpics }: Props) {
  const chartRef = useRef<ChartJS<'bar'>>(null);
  const data = getData(epics, metric, contributingEpicsData, coloredEpics);
  const navigate = useNavigate();

  const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const isClickDisabled = [InitiativeMetricType.Tasks_Added, InitiativeMetricType.Bugs].includes(metric);

    if (!chartRef.current || isClickDisabled) {
      return;
    }

    const barElement = getElementAtEvent(chartRef.current, event)[0];

    if (!barElement) {
      return;
    }

    const date = extractYearMonth(data.labels[barElement.index]);
    const { label: epicId } = data.datasets[barElement.datasetIndex];

    if (epicId && date) {
      const year = `${date.year}`;
      const month = date.month < 10 ? `0${date.month}` : `${date.month}`;
      navigate(`tasks/?filter=epic&date=${year}-${month}&epicId=${epicId}`);
    }
  };

  return (
    <div style={{ width: '75%', flex: '0 0 auto', height: 352 }}>
      <Bar ref={chartRef} options={getOptions(metric)} data={data} onClick={onClick} />
    </div>
  );
}
