import { ProjectsResponse } from '../../api/projects-client/projects-client.type';
import { Measure, MeasureMetadata, WorkPeriod } from '../../api/work-periods-client/work-periods-client.type';
import { Tab } from '../../containers/process-analysis/process-analysis.type';
import { useGlobalStore } from '../global-store/global-store';
import { useProcessAnalysisStore } from './process-analysis-store';
import { Filter, WorkPeriodFilter } from './process-analysis-store.type';

/**
 * Sets the available measures in the process analysis store.
 *
 * @param {MeasureMetadata[]} measures - The measure metadata to set.
 * @return {void}
 */
const setAvailableMeasures = (measures: MeasureMetadata[]): void => {
  useProcessAnalysisStore.setState(
    { availableMeasures: measures.filter(({ measure_name }) => measure_name !== Measure.UntestedTasks) },
    false,
    '[Process Analysis Store] setAvailableMeasures'
  );
};

/**
 * Sets the active tab in the process analysis store.
 *
 * @param {Tab} activeTab - The tab to set as active.
 * @return {void}
 */
const setActiveTab = (activeTab: Tab): void => {
  useProcessAnalysisStore.setState({ activeTab }, false, '[Process Analysis Store] setActiveTab');
};

/**
 * Sets the portfolio IDs in the process analysis store.
 *
 * @param {string[]} portfolioIds - The array of portfolio IDs to set.
 * @return {void}
 */
const setPortfolioIds = (portfolioIds: string[]): void => {
  useProcessAnalysisStore.setState({ portfolioIds }, false, '[Process Analysis Store] setPortfolioIds');
};

/**
 * Sets the team IDs in the process analysis store.
 *
 * @param {string[]} teamIds - The array of team IDs to set.
 * @return {void}
 */
const setTeamIds = (teamIds: string[]): void => {
  useProcessAnalysisStore.setState({ teamIds }, false, '[Process Analysis Store] setTeamIds');
};

/**
 * Sets the board IDs in the process analysis store.
 *
 * @param {string[]} boardIds - The array of board IDs to set.
 * @return {void}
 */
const setBoardIds = (boardIds: string[]): void => {
  useProcessAnalysisStore.setState({ boardIds }, false, '[Process Analysis Store] setBoardIds');
};

/**
 * Sets the start date in the process analysis store.
 *
 * @param {string} startDate - The start date to set.
 * @return {void}
 */
const setStartDate = (startDate: string): void => {
  useProcessAnalysisStore.setState({ startDate }, false, '[Process Analysis Store] setStartDate');
};

/**
 * Sets the end date in the process analysis store.
 *
 * @param {string} endDate - The end date to set.
 * @return {void}
 */
const setEndDate = (endDate: string): void => {
  useProcessAnalysisStore.setState({ endDate }, false, '[Process Analysis Store] setEndDate');
};

const setWorkPeriodBoardId = (boardId: string): void => {
  useProcessAnalysisStore.setState(
    { workPeriodBoardId: boardId },
    false,
    '[Process Analysis Store] setWorkPeriodBoardId'
  );
};

const setWorkPeriods = (workPeriods: WorkPeriod[]): void => {
  useProcessAnalysisStore.setState({ workPeriods }, false, '[Process Analysis Store] setWorkPeriods');
};

const setWorkPeriod = (workPeriod: WorkPeriod | null): void => {
  useProcessAnalysisStore.setState({ workPeriod }, false, '[Process Analysis Store] setWorkPeriod');
};

/**
 * Applies a filter to the portfolios based on the provided IDs, start date, and end date.
 *
 * @param {Filter} filter - The filter object containing the IDs, start date, and end date.
 * @return {void}
 */
const applyPortfoliosFilter = ({ ids, startDate, endDate }: Filter): void => {
  useProcessAnalysisStore.setState(
    { portfolioIds: ids, startDate, endDate },
    false,
    '[Process Analysis Store] applyPortfolioFilter'
  );
};

/**
 * Applies a filter to the teams based on the provided IDs, start date, and end date.
 *
 * @param {Filter} filter - The filter object containing the IDs, start date, and end date.
 * @return {void}
 */
const applyTeamsFilter = ({ ids, startDate, endDate }: Filter): void => {
  useProcessAnalysisStore.setState(
    { teamIds: ids, startDate, endDate },
    false,
    '[Process Analysis Store] applyTeamsFilter'
  );
};

/**
 * Applies a filter to the boards based on the provided IDs, start date, end date, board measure, and sprint ID.
 *
 * @param {Filter} filter - The filter object containing the IDs, start date, end date, board measure, and sprint ID.
 * @return {void}
 */
const applyBoardsFilter = ({ ids, startDate, endDate }: Filter): void => {
  useProcessAnalysisStore.setState(
    { boardIds: ids, startDate, endDate },
    false,
    '[Process Analysis Store] applyBoardsFilter'
  );
};

/**
 * Applies a filter to the work periods based on the provided filter object.
 *
 * @param {WorkPeriodFilter} filter - The filter object containing the work period settings.
 * @return {void}
 */
const applyWorkPeriodsFilter = (filter: WorkPeriodFilter): void => {
  useProcessAnalysisStore.setState(filter, false, '[Process Analysis Store] applyWorkPeriodsFilter');
};

const setLocalMessage = (message: string): void => {
  useProcessAnalysisStore.setState({ localMessage: message }, false, '[Process Analysis Store] setLocalMessage');
};

const resetLocalMessage = (): void => {
  useProcessAnalysisStore.setState({ localMessage: null }, false, '[Process Analysis Store] resetLocalMessage');
};

/**
 * Initializes the process analysis state by setting the portfolio IDs, team IDs, and marking the state as initialized.
 *
 * @param {ProjectsResponse[]} teams - An array of project responses used to set the team IDs.
 * @return {void}
 */
const initProcessAnalysisState = (teams: ProjectsResponse[] = []): void => {
  const globalPortfolioId = useGlobalStore.getState().portfolio?.id;
  const localPortfolioId = useProcessAnalysisStore.getState().portfolioIds[0];
  if (globalPortfolioId && globalPortfolioId !== localPortfolioId) {
    setPortfolioIds([globalPortfolioId]);
  }

  const localTeamId = useProcessAnalysisStore.getState().teamIds[0];
  if (!localTeamId) {
    setTeamIds(teams.length ? [teams[0]?.id] : []);
  }

  const localBoardId = useProcessAnalysisStore.getState().boardIds[0];
  if (!localBoardId) {
    setBoardIds([teams[0]?.subprojects[0]?.id]);
  }

  const localWorkPeriodBoardId = useProcessAnalysisStore.getState().workPeriodBoardId;
  if (!localWorkPeriodBoardId) {
    setWorkPeriodBoardId(teams[0]?.subprojects[0]?.id);
  }

  useProcessAnalysisStore.setState({ initialized: true }, false, '[Process Analysis Store] initProcessAnalysisState');
};

export {
  applyBoardsFilter,
  applyPortfoliosFilter,
  applyTeamsFilter,
  applyWorkPeriodsFilter,
  initProcessAnalysisState,
  resetLocalMessage,
  setActiveTab,
  setAvailableMeasures,
  setBoardIds,
  setEndDate,
  setLocalMessage,
  setPortfolioIds,
  setStartDate,
  setTeamIds,
  setWorkPeriod,
  setWorkPeriodBoardId,
  setWorkPeriods,
};
