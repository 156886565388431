import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import { resetLocalMessage } from '../../store/process-analysis-store/process-analysis-store.actions';
import { useViewType } from '../../store/process-analysis-store/process-analysis-store.hooks';
import { PageHeader } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { PageContainer } from '../process/process.styled';
import { AssessmentView } from './assessment-view/assessment-view';
import { ComparisonView } from './comparison-view/comparison-view';
import { useAvailableMeasuresData } from './process-analysis.hooks';
import { Tab, ViewType, WorkPeriodType } from './process-analysis.type';
import { ScopeExplorer } from './scope-explorer/scope-explorer';
import { SingularView } from './singular-view';

export function ProcessAnalysis() {
  useDocumentTitle('Process Analysis - Bloomfilter');
  const viewType = useViewType();
  const localMessage = useProcessAnalysisStore((state) => state.localMessage);
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);

  const { isFetching } = useAvailableMeasuresData();

  const [init, setInit] = useState<boolean>(false);
  useEffectOnce(() => setInit(true));

  return (
    <MenuDrivenContainer>
      <BrandedLoadingOverlay visible={isFetching} transitionDuration={30} variant="colored" />
      <div style={{ height: 40 }} />
      <PageContainer style={{ paddingLeft: 8 }}>
        {localMessage && activeTab === Tab.WorkPeriods && workPeriodType === WorkPeriodType.Defined && (
          <PageHeaderMessage message={localMessage} color="red" onClose={resetLocalMessage} />
        )}
        <PageHeader>Process Analysis</PageHeader>
        <ScopeExplorer />
        {init && (
          <>
            {viewType === ViewType.Comparison && <ComparisonView />}
            {viewType === ViewType.Single && <SingularView />}
            {viewType === ViewType.Assessment && <AssessmentView />}
          </>
        )}
      </PageContainer>
    </MenuDrivenContainer>
  );
}
