import { useQuery } from '@tanstack/react-query';
import { Fragment, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSprint } from '../../api/sprints-client/sprints-client';
import { Sprint } from '../../api/sprints-client/sprints-client.type';
import { TaskAuditLogEntry } from '../../api/tasks-client/task-client.type';
import { fetchTaskAuditLog } from '../../api/tasks-client/tasks-client';
import { AuditLog } from '../../components/audit-log/audit-log';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb';
import { CrumbItem } from '../../components/breadcrumb/breadcrumb.type';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { ProjectContext, SidebarContext } from '../../contexts';
import { BreadcrumbContainer, OuterPaddingDiv, TitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { onPortfolioBreadcrumbClick } from '../side-bar/side-bar.helper';

export const TaskAuditLog = () => {
  const { taskId = '' } = useParams<{ taskId: string }>();
  const { sprintId = '' } = useParams<{ sprintId: string }>();
  const [auditLog, setAuditLog] = useState<TaskAuditLogEntry[]>([]);
  const [sprint, setSprint] = useState<Sprint | null>(null);
  const { project, setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);
  const auditLogQuery = useQuery(
    ['taskId', taskId],
    taskId ? () => fetchTaskAuditLog(taskId) : () => Promise.reject('Task ID is required'),
    {
      onSuccess: setAuditLog,
      onError: ({ error }: { error: any }) => {
        return Promise.reject(error?.response?.data || 'Error whiel fetching sprint audit log');
      },
    }
  );
  useQuery(
    ['sprint', sprintId],
    () => (sprintId ? fetchSprint(sprintId) : Promise.reject('Unable to resolve SprintId or SprintID is missing')),
    {
      enabled: !!sprintId,
      onSuccess: setSprint,
      onError: () => console.error('There was an issue fetching sprint data.'),
    }
  );
  const breadCrumbitems: CrumbItem[] = [
    {
      labelName: 'Portfolio',
      href: '/application/dashboard',
      onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
    },
    { labelName: (project?.name as string) || 'Project', href: `/application/project/${project?.id}` },
    {
      labelName: (sprint?.name as string) || 'Sprint',
      href: `/application/project/${project?.id}/sprint-assessment/${sprintId}`,
    },
    {
      labelName: (sprint?.name as string) + ' Audit Log' || 'Sprint',
      href: `/application/project/${project?.id}/sprint-assessment/${sprintId}/audit-log`,
    },
    { labelName: 'Task Audit Log' },
  ];

  return (
    <Fragment>
      <BrandedLoadingOverlay visible={auditLogQuery.isLoading} transitionDuration={300} variant="colored" />

      <MenuDrivenContainer project={project ? project : undefined}>
        <OuterPaddingDiv>
          <BreadcrumbContainer data-testid="breadcrumb">
            <Breadcrumb crumbItems={breadCrumbitems} />
          </BreadcrumbContainer>
          <TitleHeadingTag>Task Audit Log</TitleHeadingTag>
          <AuditLog sprint={sprint} entries={auditLog} />
        </OuterPaddingDiv>
      </MenuDrivenContainer>
    </Fragment>
  );
};
