import { QueryObserverResult } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrganizations } from '../../api/organization-client/organization-client.hooks';
import { OrganizationResponse } from '../../api/organization-client/organization-client.type';
import { usePortfolios } from '../../api/portfolio-client/portfolio-client.hooks';
import { Portfolio } from '../../api/portfolio-client/portfolio-client.type';
import { useProjects } from '../../api/projects-client/projects-client.hooks';
import { getRequestToken } from '../../helpers/storage/storage';
import { useGlobalStore } from '../../store/global-store/global-store';
import { initGlobalState, setTeams } from '../../store/global-store/global-store.actions';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import { initProcessAnalysisState } from '../../store/process-analysis-store/process-analysis-store.actions';

/**
 * Initializes the data for the application.
 *
 * @return {void}
 */
const useDataInitializer = () => {
  const initializedGlobalState = useGlobalStore((state) => state.initialized);
  const initializedProcessAnalysisState = useProcessAnalysisStore((state) => state.initialized);

  const isValidAccess = Boolean(getRequestToken());

  const { organizations = [], query: organizationsQuery } = useOrganizations({
    enabled: isValidAccess && !initializedGlobalState,
  });
  const { portfolios = [], query: portfoliosQuery } = usePortfolios({
    enabled: isValidAccess && !initializedGlobalState,
  });
  const { projects: teams = [] } = useProjects(null, { enabled: isValidAccess && !initializedGlobalState });

  // Initialize global state
  useEffect(() => {
    if (organizations.length && portfolios.length && !initializedGlobalState) {
      initGlobalState(organizations, portfolios);
    }
  }, [organizations, portfolios, initializedGlobalState]);

  // Initialize teams
  useEffect(() => {
    if (teams.length) {
      setTeams(teams);
    }
  }, [teams]);

  // Initialize process analysis state
  useEffect(() => {
    if (teams.length && !initializedProcessAnalysisState) {
      initProcessAnalysisState(teams);
    }
  }, [teams, initializedProcessAnalysisState]);

  useHandleErrors(organizationsQuery, portfoliosQuery);
};

/**
 * Handles errors from the organizations and portfolios queries.
 *
 * @param {QueryObserverResult<OrganizationResponse[]>} organizationsQuery - The result of the organizations query.
 * @param {QueryObserverResult<Portfolio[]>} portfoliosQuery - The result of the portfolios query.
 * @return {void}
 */
const useHandleErrors = (
  organizationsQuery: QueryObserverResult<OrganizationResponse[]>,
  portfoliosQuery: QueryObserverResult<Portfolio[]>
) => {
  const navigate = useNavigate();

  const isError = organizationsQuery.isError || portfoliosQuery.isError;
  const queryError = organizationsQuery.isError ? organizationsQuery.error : portfoliosQuery.error;

  useEffect(() => {
    if (isError) {
      const error = queryError as { status: number; statusText: string };
      const errorState = {
        errorMessage: error?.statusText,
        errorStatus: error?.status,
      };
      navigate(`/application/out-of-bloom`, { state: errorState });
    }
  }, [isError, navigate, queryError]);
};

export { useDataInitializer };
