import { styled } from '@linaria/react';
import {
  ActiveElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { PaceStatus } from '../../../../api/work-periods-client/work-periods-client.type';
import { createWeekendBackgroundPlugin } from '../../../../helpers/chart-plugins/weekend-background-element';
import { newCOLORS } from '../../../../styles/colors';
import { HeadingTag } from '../../../../styles/shared-styled-components';
import { DataPoint } from '../section-pace.types';
import { getStatusColor } from './work-on-track.helpers';
import { WorkOnTrackChartDataset } from './work-on-track.type';

const statusMapping: { [key: string]: number } = {
  [PaceStatus.OffTrack]: 0,
  [PaceStatus.AtRisk]: 1,
  [PaceStatus.OnTrack]: 2,
};

type Props = {
  data: DataPoint[];
  labels: string[];
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export const WorkOnTrack = ({ data, labels }: Props) => {
  const statusColors = data.map((dataPoint) => getStatusColor(dataPoint.status));

  const formattedData = {
    labels,
    datasets: [
      {
        label: 'Work Done, on track',
        order: 5,
        stack: 'Stack 5',
        data: data.map((dataPoint) => statusMapping[dataPoint.status]),
        fill: false,
        pointBorderColor: statusColors,
        pointBackgroundColor: statusColors,
        pointStyle: 'circle',
        pointRadius: 4,
        pointHoverRadius: 5,
        borderWidth: 2,
        borderColor: newCOLORS.lightGray,
        backgroundColor: newCOLORS.lightGray,
        totalPoints: data.map((dataPoint) => dataPoint.complete),
      },
    ],
  };

  const defaultChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: false,
    borderWidth: 5,
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 20,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: 'Status',
          font: {
            size: 16,
          },
        },
        ticks: {
          callback: (value: string | number) => {
            return Object.keys(statusMapping).find((key: string) => statusMapping[key] === Number(value));
          },
          stepSize: 1,
          font: {
            size: 14,
          },
        },
      },
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      let cursorStyle = 'default';

      const isOnElement = elements.length > 0;
      if (isOnElement) {
        cursorStyle = 'pointer';
      }
      if (event?.native?.target) {
        (event.native.target as HTMLElement).style.cursor = cursorStyle;
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        labels: {
          boxWidth: 2,
          boxHeight: 12,
          pointStyle: 'rect',
          generateLabels: () => {
            const items = [
              {
                text: 'Off Track',
                fontColor: newCOLORS.darkGray,
                fillStyle: newCOLORS.coral,
                strokeStyle: newCOLORS.coral,
                hidden: false,
                index: 0,
              },
              {
                text: 'At Risk',
                fontColor: newCOLORS.darkGray,
                fillStyle: newCOLORS.lightYellow,
                strokeStyle: newCOLORS.lightYellow,
                hidden: false,
                index: 1,
              },
              {
                text: 'On Track',
                fontColor: newCOLORS.darkGray,
                fillStyle: newCOLORS.green,
                strokeStyle: newCOLORS.green,
                hidden: false,
                index: 2,
              },
            ];
            return items;
          },
        },
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItems: TooltipItem<'line'>) => {
            const dataset: WorkOnTrackChartDataset<'line'> = tooltipItems.chart.data
              .datasets[0] as WorkOnTrackChartDataset<'line'>;
            return `Work Done: ${dataset.totalPoints[tooltipItems.dataIndex]}`;
          },
        },
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
    },
  };

  const weekendOrHolidays = data.map((dataPoint) => dataPoint.weekend_or_holiday);

  return (
    <ChartContainer>
      <CenterHeadingTag>Is work on track to match pace?</CenterHeadingTag>
      <div style={{ height: '200px', display: 'flex' }}>
        <div style={{ width: '99%' }}>
          <Line
            options={defaultChartOptions}
            data={formattedData}
            plugins={[createWeekendBackgroundPlugin(weekendOrHolidays) as any]}
          />
        </div>
      </div>
    </ChartContainer>
  );
};
const ChartContainer = styled.div`
  margin-bottom: 25px;
`;
const CenterHeadingTag = styled(HeadingTag)`
  text-align: center;
`;
