import { Fragment } from 'react/jsx-runtime';
import { KeyMeasuresOverTime } from '../key-measures/key-measures-over-time';
import { MonthlyAverages } from '../key-measures/monthly-averages';
import { useKeyMeasuresOverTimeData, useMonthlyAveragesData } from './comparison-view.hooks';

export function ComparisonView() {
  const { data: monthlyAveragesData, isFetching: isFetchingMonthlyAveragesData } = useMonthlyAveragesData();
  const { data: keyMeasuresOverTimeData, isFetching: isFetchingKeyMeasuresOverTimeData } = useKeyMeasuresOverTimeData();

  return (
    <Fragment>
      <MonthlyAverages tableData={monthlyAveragesData || {}} dataIsLoading={isFetchingMonthlyAveragesData} />
      <KeyMeasuresOverTime
        tableData={keyMeasuresOverTimeData || {}}
        dataIsLoading={isFetchingKeyMeasuresOverTimeData}
      />
    </Fragment>
  );
}
