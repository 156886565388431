import { styled } from '@linaria/react';
import { JiraHistoryTask } from '../../api/sprints-client/sprints-client.type';

export const Card = ({ task }: { task: JiraHistoryTask }) => (
  <TaskCard>
    <TaskName>{task.name}</TaskName>
    <TaskPoints>{task.points || '-'}</TaskPoints>
    <TaskCode>{task.code}</TaskCode>
  </TaskCard>
);

const TaskCard = styled.div`
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 12px;
  background-color: #ffffff;
  word-wrap: break-word;
  overflow: hidden;
`;

const TaskName = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
  word-wrap: normal;
  overflow-wrap: break-word;
`;

const TaskPoints = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 8px;
  font-size: 12px;
  font-weight: bold;
`;

const TaskCode = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;
