import { Select } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createInitiative } from '../../api/initiative-client/initiative-client';
import { useInitiatives } from '../../api/initiative-client/initiative-client.hooks';
import { PreSaveInitiative } from '../../api/initiative-client/initiative-client.type';
import { usePortfolios } from '../../api/portfolio-client/portfolio-client.hooks';
import { Portfolio } from '../../api/portfolio-client/portfolio-client.type';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { PortfolioContext, ProjectContext, SidebarContext } from '../../contexts';
import { refactorDropdownData } from '../../helpers';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { newCOLORS } from '../../styles/colors';
import { PageHeader, StyledButton } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { BreadcrumbContainer } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { onPortfolioBreadcrumbClick } from '../side-bar/side-bar.helper';
import { GeneralViewNames } from '../side-bar/side-bar.type';
import { CreateNewInitiativeExplainer } from './create-new-initiative-explainer';
import { InitiativeModal } from './initiative-modal/initiative-modal';
import { InitiativesList } from './initiatives-list/initiatives-list';

const isGlobalScopeEnabled = import.meta.env.VITE_FEATURE_FLAG_GLOBAL_ORG_PORTFOLIO === 'true';

export function Strategy() {
  useDocumentTitle('Strategy - Bloomfilter');
  const { portfolio, setPortfolio } = useContext(PortfolioContext);
  const { setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);
  const [portfolios, setPortfolios] = useState([] as Portfolio[]);
  const [selectedPortfolio, setSelectedPortfolio] = useState<Portfolio | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState(location.state?.message || '');
  const ref = useClickOutside(() => {
    setMessage('');
    navigate('.', { replace: true });
  }, ['mouseup', 'touchend']);

  const { portfolios: portfoliosData, query: portfoliosQuery } = usePortfolios();

  // TODO: Remove local portfolio after the full migration to the global org/portfolio selector
  useEffect(() => {
    if (portfoliosData.length > 0) {
      setPortfolios(portfoliosData);
      if (portfolio) {
        setSelectedPortfolio(portfolio);
      } else {
        setSelectedPortfolio(portfoliosData[0]);
      }
    }
  }, [portfoliosData, portfolio]);

  const { query: initiativesQuery, initiatives = [] } = useInitiatives(selectedPortfolio?.id, {
    enabled: !!selectedPortfolio?.id,
  });

  const createInitiativeMutation = useMutation(
    ['createInitiative', selectedPortfolio],
    (initiative: PreSaveInitiative) =>
      selectedPortfolio ? createInitiative(selectedPortfolio.id, initiative) : Promise.reject('No portfolio selected'),
    {
      onSuccess: () => initiativesQuery.refetch(),
    }
  );

  const hasInitiatives = initiativesQuery.isSuccess && !!initiatives.length;

  return (
    <MenuDrivenContainer>
      <BrandedLoadingOverlay
        visible={portfoliosQuery.isLoading || initiativesQuery.isFetching || createInitiativeMutation.isLoading}
        variant="colored"
      />
      <div style={{ padding: '15px' }}>
        <BreadcrumbContainer>
          <Breadcrumb
            crumbItems={[
              {
                labelName: portfolio?.organization?.name || 'Portfolio',
                href: '/application/dashboard',
                onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
              },
              { labelName: GeneralViewNames.Strategy },
            ]}
          />
        </BreadcrumbContainer>
        <div ref={ref}>{message ? <PageHeaderMessage message={message} color="green" /> : null}</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <PageHeader>{GeneralViewNames.Strategy}</PageHeader>
            {!isGlobalScopeEnabled && portfolios && portfolios.length > 1 && selectedPortfolio ? (
              <Select
                style={{ width: 275 }}
                data={refactorDropdownData(portfolios)}
                value={selectedPortfolio.id}
                onChange={(value) => {
                  setSelectedPortfolio(portfolios.find((portfolio) => portfolio.id === value) || null);
                  setPortfolio(portfolios.find((portfolio) => portfolio.id === value) || null);
                }}
              />
            ) : null}
            {hasInitiatives ? (
              <StyledButton
                size={'medium'}
                type={'primary'}
                firstColor={newCOLORS.indigo}
                secondColor={newCOLORS.white}
                disable={false}
                onClick={open}
              >
                Create initiative
              </StyledButton>
            ) : null}
          </div>
        </div>
        {!initiativesQuery.isLoading &&
          selectedPortfolio &&
          (hasInitiatives ? (
            <InitiativesList initiatives={initiatives} portfolioId={selectedPortfolio.id} />
          ) : (
            <CreateNewInitiativeExplainer handleCreateInitiative={open} />
          ))}
      </div>
      {selectedPortfolio && (
        <InitiativeModal
          opened={opened}
          title="Create Initiative"
          portfolioId={selectedPortfolio.id}
          handleClose={close}
          handleSubmit={createInitiativeMutation.mutateAsync}
          setMessage={setMessage}
        />
      )}
    </MenuDrivenContainer>
  );
}
