import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIntegrationsJDC } from '../../api/integrations-client/integrations-client';
import { useIntegrationUrl } from '../../api/integrations-client/integrations-client.hooks';
import { ExternalService } from '../../api/integrations-client/integrations-client.type';
import { useProjects } from '../../api/projects-client/projects-client.hooks';
import Checkmark from '../../assets/images/checkmark.png';
import { FileUploadPopup } from '../../components/file-upload-popup/file-upload-popup';
import { GlobalContext, PortfolioContext, UserContext } from '../../contexts';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { useOrganizationId } from '../../helpers/auth-helpers/auth.hooks';
import { newCOLORS } from '../../styles/colors';
import { StyledButton } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { ErrorSource, GlobalMessageCategory, GlobalMessageState } from '../global-message/global-message.type';
import { IntegrationsContext } from './context/integrations.context';
import { integrationViews } from './integrations.data';
import styles from './integrations.module.css';
import { IntegrationView } from './integrations.type';
import { UrlModal } from './jira/url-modal';

type Props = {
  setActiveStep: Dispatch<SetStateAction<number>>;
};

export function IntegrationCards({ setActiveStep }: Props) {
  const { cachePortfolio } = useContext(PortfolioContext);
  const { user } = useContext(UserContext);
  const { setInitJDC } = useContext(IntegrationsContext);
  const organizationId = useOrganizationId();

  const [showUploadPopUp, setShowUploadPopUp] = useState(false);
  const [atlassianModalOpen, setAtlassianModalOpen] = useState(false);
  const [activeIntegration, setActiveIntegration] = useState<ExternalService | null>(null);

  const { projects = [] } = useProjects();

  const navigate = useNavigate();
  const { setGlobalMessage } = useContext(GlobalContext);

  const { url } = useIntegrationUrl(activeIntegration, {
    enabled: !!activeIntegration && activeIntegration !== ExternalService.JDC,
  });

  useEffect(() => {
    if (url) {
      const receivedUrl = new URL(url);
      const params = new URLSearchParams(receivedUrl.search);
      params.set('state', `${url.toUpperCase()},${organizationId || ''}`);
      const updatedQueryString = params.toString();

      // Make sure to cache the selected portfolio before we re-direct to the external system
      cachePortfolio();

      window.location.href = `${receivedUrl.origin}${receivedUrl.pathname}?${updatedQueryString}`;
    }
  }, [url, organizationId, cachePortfolio]);

  const onClickJDC = async () => {
    if (!organizationId) {
      return;
    }

    const response = await getIntegrationsJDC(organizationId);

    if (response.length > 0) {
      setInitJDC(true);
      navigate('/application/integrations/jdc');
    } else {
      setGlobalMessage({
        type: GlobalMessageCategory.APP_ERROR,
        source: ErrorSource.JDC,
        state: GlobalMessageState.ERROR,
      });
    }
  };

  return (
    <main>
      <div id={styles.contentContainer}>
        <h3>Choose the platforms you would like to integrate with.</h3>
        <h4>You can always come back and add more!</h4>
        {showUploadPopUp && <FileUploadPopup closePopUp={() => setShowUploadPopUp(false)} show={showUploadPopUp} />}
        <div className={styles.integrationsCardGrid}>
          {integrationViews
            .filter((integration) => integration.enabled)
            .map((integration) => {
              if (integration.name === ExternalService.Jira) {
                return (
                  <IntegrationCard
                    key={integration.name}
                    integration={integration}
                    onClick={() => {
                      trackEvent(AnalyticsEventType.JiraIntegrationTapped, { userContext: user });
                      setActiveStep(0);
                      setAtlassianModalOpen(true);
                    }}
                    isComplete={user?.subscription_status === 'unpaid' && !(projects.length === 0)}
                  />
                );
              }
              return (
                <IntegrationCard
                  key={integration.name}
                  integration={integration}
                  onClick={() => {
                    setActiveIntegration(integration.name);

                    if (integration.name === ExternalService.JDC) {
                      onClickJDC();
                    } else {
                      setActiveStep(0);
                    }
                  }}
                />
              );
            })}
          <UrlModal isOpened={atlassianModalOpen} close={() => setAtlassianModalOpen(false)} />
        </div>
      </div>
    </main>
  );
}

function IntegrationCard({
  integration,
  onClick,
  link,
  isComplete,
}: {
  integration: IntegrationView;
  onClick: () => void;
  link?: ReactNode;
  isComplete?: boolean;
}) {
  return (
    <div id={styles.card}>
      <div className={styles.cardContentContainer}>
        <div className={styles.flexColumn}>
          {integration.icon}
          <h4 style={{ textAlign: 'center' }}>{integration.title}</h4>
        </div>
        <div className={styles.flexColumn}>
          {link ? link : null}
          {isComplete ? (
            <img className={styles.logo} height={35} width={35} src={Checkmark} alt={'Success'} />
          ) : (
            <StyledButton
              size={'medium'}
              type={'primary'}
              firstColor={newCOLORS.indigo}
              secondColor={newCOLORS.white}
              disable={false}
              style={{ margin: 8 }}
              onClick={onClick}
            >
              {integration.buttonText}
            </StyledButton>
          )}
        </div>
      </div>
    </div>
  );
}
