import { styled } from '@linaria/react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Sprint, SprintAuditLogEntry } from '../../api/sprints-client/sprints-client.type';
import { TaskAuditLogEntry } from '../../api/tasks-client/task-client.type';
import { ProjectContext } from '../../contexts';
import { formatDate } from '../../helpers/timezone/timezone';
import { DateTemplate } from '../../helpers/timezone/timezone.type';
import { newCOLORS } from '../../styles/colors';

export type AuditLogProps = {
  entries: SprintAuditLogEntry[] | TaskAuditLogEntry[];
  sprint: Sprint | null;
};

export type AuditLogEntryProps = {
  entry: SprintAuditLogEntry | TaskAuditLogEntry;
  clickable?: boolean;
  sprint: Sprint | null;
};

function AuditLogEntry(props: AuditLogEntryProps) {
  const { project } = useContext(ProjectContext);
  const entryLink =
    props.entry.type === 'sprint'
      ? `/application/project/${project?.id}/sprint-assessment/${props.entry.id}`
      : `/application/project/${project?.id}/sprint/${props.sprint?.id}/task/${props.entry.id}/audit-log`;

  return (
    <AuditLogTableRow>
      <AuditLogTableCell>
        {formatDate(
          props.entry.date,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          DateTemplate.DateTimeWithTimezone
        )}
      </AuditLogTableCell>
      <AuditLogTableCell>
        {props.clickable ? <Link to={entryLink}>{props.entry.identifier}</Link> : <div> {props.entry.identifier}</div>}
      </AuditLogTableCell>
      <AuditLogTableCell>{props.entry.message}</AuditLogTableCell>
    </AuditLogTableRow>
  );
}

export function AuditLog(props: AuditLogProps) {
  return (
    <AuditLogTable>
      <AuditLogHeaderRow>
        <tr>
          <AuditLogHeader>Date</AuditLogHeader>
          <AuditLogHeader>Card</AuditLogHeader>
          <AuditLogHeader>Action</AuditLogHeader>
        </tr>
      </AuditLogHeaderRow>
      <tbody>
        {props.entries.map((entry: SprintAuditLogEntry, index) => {
          return <AuditLogEntry sprint={props.sprint} key={`${index}-${entry.date}`} entry={entry} clickable={true} />;
        })}
      </tbody>
    </AuditLogTable>
  );
}

const AuditLogHeader = styled.th`
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const AuditLogTable = styled.table`
  width: 100%;
  margin-top: 20px;
  padding: 30px;
`;

const AuditLogHeaderRow = styled.thead`
  padding-bottom: 30px;
`;

const AuditLogTableRow = styled.tr`
  padding: 5px;
  font-size: 20px;
  border-bottom: 1px solid ${newCOLORS.gray};
`;

const AuditLogTableCell = styled.td`
  padding: 5px;
`;
