import dayjs from 'dayjs';
import { useInitiativeFocus } from '../../../api/portfolio-client/portfolio-client.hooks';
import { ProcessAnalysisChartData } from '../../../api/portfolio-client/portfolio-client.type';
import { useProjectInitiativeFocus } from '../../../api/projects-client/projects-client.hooks';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDateRange } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { Tab } from '../process-analysis.type';

/**
 * Fetches initiative focus data for either the selected portfolios or teams based on the active tab.
 *
 * @returns {{ data: ProcessAnalysisChartData | undefined; isFetching: boolean }} - Object containing the initiative focus data and a boolean indicating whether the data is being fetched
 */
const useInitiativeFocusData = (): { data: ProcessAnalysisChartData | undefined; isFetching: boolean } => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const portfolioIds = useProcessAnalysisStore((state) => state.portfolioIds);
  const teamIds = useProcessAnalysisStore((state) => state.teamIds);
  const { startDate, endDate } = useDateRange();

  const { initiativeFocus: portfolioInitiativeFocus, query: portfolioInitiativeFocusQuery } = useInitiativeFocus(
    portfolioIds[0],
    dayjs(startDate).format('YYYY-MM'),
    dayjs(endDate).format('YYYY-MM'),
    {
      enabled: activeTab === Tab.Portfolios && portfolioIds.length > 0,
    }
  );

  const { initiativeFocus: projectInitiativeFocus, query: projectInitiativeFocusQuery } = useProjectInitiativeFocus(
    teamIds[0],
    dayjs(startDate).format('YYYY-MM'),
    dayjs(endDate).format('YYYY-MM'),
    {
      enabled: activeTab === Tab.Teams && teamIds.length > 0,
    }
  );

  const data = activeTab === Tab.Portfolios ? portfolioInitiativeFocus : projectInitiativeFocus;
  const isFetching = portfolioInitiativeFocusQuery.isFetching || projectInitiativeFocusQuery.isFetching;

  return { data, isFetching };
};

export { useInitiativeFocusData };
