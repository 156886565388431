import { ProcessAnalysisChartData } from '../../../api/portfolio-client/portfolio-client.type';

/**
 * Sorts the initiative headers in the given initiatives data.
 *
 * @param {ProcessAnalysisChartData | undefined} initiativesData - The ProcessAnalysisChartData to sort.
 * @returns {string[]} - A sorted list of initiative headers.
 */
const sortInitiatives = (initiativesData: ProcessAnalysisChartData | undefined): string[] => {
  return Object.keys(initiativesData || {})
    .filter((header) => header !== 'labels')
    .toSorted();
};

export { sortInitiatives };
