import { styled } from '@linaria/react';
import { Flex, Select, Tooltip } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteInitiative, updateInitiative } from '../../../api/initiative-client/initiative-client';
import { useInitiative, useInitiatives } from '../../../api/initiative-client/initiative-client.hooks';
import {
  ExtendedInitiative,
  InitiativeSource,
  PreSaveInitiative,
} from '../../../api/initiative-client/initiative-client.type';
import { icons } from '../../../assets/icons/icons';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb';
import { Button } from '../../../components/button/button';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../../components/page-header-message/page-header-message';
import { ProjectContext, SidebarContext } from '../../../contexts';
import { useDocumentTitle } from '../../../helpers/general-helpers';
import { newCOLORS } from '../../../styles/colors';
import { BreadcrumbContainer } from '../../../styles/shared-styled-components';
import { Text } from '../../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../../menu-driven-container/menu-driven-container';
import { onPortfolioBreadcrumbClick } from '../../side-bar/side-bar.helper';
import { iconNavigate } from '../assets';
import { InitiativeDeleteModal } from '../initiative-delete/initiative-delete-modal';
import { InitiativeModal } from '../initiative-modal/initiative-modal';
import { getSourceIcon, getSourceLabel } from '../initiatives-list/initiatives-list.helpers';
import { getSelectOptions } from './initiative-performance.helpers';
import { SectionEpics } from './section-epics/section-epics';
import { SectionStatus } from './section-status/section-status';
import { SectionTeams } from './section-teams/section-teams';

const showExternalInitiatives = import.meta.env.VITE_FEATURE_FLAG_EXTERNAL_INITIATIVES === 'true';

export function InitiativePerformance() {
  useDocumentTitle('Initiative Performance - Bloomfilter');
  const { navItems, setNavItems } = useContext(SidebarContext);
  const { setProject } = useContext(ProjectContext);
  const [initiative, setInitiative] = useState<ExtendedInitiative | undefined>(undefined);
  const { portfolioId, initiativeId } = useParams();
  const navigate = useNavigate();

  const [openedEdit, { open: openEditModal, close: closeEditModal }] = useDisclosure(false);
  const [openedDelete, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);

  // check if the epic is completed and associated epics have active subtasks
  const activeMessage =
    'This initiative has passed its end date. Tasks are continuing to be added, completed, or removed in epics mapped to this initiative.';
  const defaultMessage = initiative?.contains_active_epics_after_close ? activeMessage : '';
  const [initiativePerformanceMessage, setInitiativePerformanceMessage] = useState(defaultMessage);
  useEffect(() => {
    setInitiativePerformanceMessage(defaultMessage);
  }, [defaultMessage]);
  const ref = useClickOutside(() => setInitiativePerformanceMessage(''), ['mouseup', 'touchend']);

  const selectRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  const { query: initiativesQuery } = useInitiatives(portfolioId, { enabled: Boolean(portfolioId) });

  const { query: initiativeQuery } = useInitiative(portfolioId, initiativeId, {
    enabled: Boolean(portfolioId) && Boolean(initiativeId),
    onError: () => navigate('/application/strategy'),
  });

  useEffect(() => {
    if (initiativeQuery.data) {
      setInitiative(initiativeQuery.data);
    }
  }, [initiativeQuery.data]);

  const editInitiativeMutation = useMutation(
    ['editInitiative', initiativeId],
    (initiative: PreSaveInitiative) =>
      portfolioId
        ? updateInitiative(portfolioId, { id: initiativeId, ...initiative })
        : Promise.reject('Cannot update initiative'),
    {
      onSuccess: () => {
        closeEditModal();
        initiativesQuery.refetch();
        initiativeQuery.refetch();
      },
    }
  );

  const deleteInitiativeMutation = useMutation(
    ['deleteInitiative', initiativeId],
    () =>
      portfolioId && initiativeId
        ? deleteInitiative(portfolioId, initiativeId)
        : Promise.reject('Cannot delete initiative'),
    {
      onSuccess: () => {
        queryClient.removeQueries(['getInitiative', portfolioId, initiativeId]);
        closeDeleteModal();
        navigate('/application/strategy', {
          state: { message: `${initiative?.name} has been successfully removed.` },
        });
      },
    }
  );

  const loading = initiativeQuery.isFetching || editInitiativeMutation.isLoading;

  return (
    <MenuDrivenContainer containerStyles={{ overflow: loading ? 'hidden' : 'visible' }}>
      <BrandedLoadingOverlay visible={loading} transitionDuration={30} variant="colored" />
      {initiative && (
        <div style={{ padding: '15px' }}>
          <BreadcrumbContainer>
            <Breadcrumb
              crumbItems={[
                {
                  labelName: 'Portfolio',
                  href: '/application/dashboard',
                  onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
                },
                { labelName: 'Strategy', href: '/application/strategy' },
                { labelName: initiative.name },
              ]}
            />
          </BreadcrumbContainer>
          <div ref={ref}>
            {initiativePerformanceMessage ? (
              initiativePerformanceMessage === activeMessage ? (
                <PageHeaderMessage
                  message="This initiative has passed its end date. "
                  navigationMessage="Tasks are continuing"
                  messageAfterLink=" to be added, completed, or removed in epics mapped to this initiative."
                  color="red"
                  navigationPath="tasks/?filter=active"
                />
              ) : (
                <PageHeaderMessage message={initiativePerformanceMessage} color="green" />
              )
            ) : null}
          </div>
          <PageTitleContainer>
            <TitleComponent title={initiative.name} maxWidth="60%" />
            <div style={{ maxWidth: '40%' }}>
              <div style={{ display: 'flex', gap: '30px' }}>
                <Actions initiative={initiative} onEdit={openEditModal} onDelete={openDeleteModal} />
                <SelectContainer>
                  <SelectLabel>Initiative</SelectLabel>
                  <div ref={selectRef}>
                    <StyledSelect
                      label=""
                      size="xs"
                      styles={{
                        wrapper: { width: 'auto' },
                      }}
                      value={initiative.id}
                      data={getSelectOptions(initiativesQuery.data, initiative)}
                      onChange={(initiativeId) => {
                        navigate(`/application/strategy/${portfolioId}/${initiativeId}`);
                      }}
                    />
                  </div>
                </SelectContainer>
              </div>
            </div>
          </PageTitleContainer>
          {showExternalInitiatives && (
            <Flex direction="row" align="center" gap={4} style={{ marginBottom: 8 }}>
              <img src={getSourceIcon(initiative.source)} width={20} height={20} alt={initiative.source} />
              <Text>{getSourceLabel(initiative.source)}</Text>
            </Flex>
          )}
          <Description>{initiative.description}</Description>
          <InitiativePerformanceContent initiative={initiative} portfolioId={portfolioId} />
        </div>
      )}
      {portfolioId && (
        <InitiativeModal
          opened={openedEdit}
          title="Edit Initiative"
          portfolioId={portfolioId}
          initiative={initiative}
          handleClose={closeEditModal}
          handleSubmit={editInitiativeMutation.mutateAsync}
          setMessage={setInitiativePerformanceMessage}
        />
      )}
      {initiative && (
        <InitiativeDeleteModal
          opened={openedDelete}
          initiativeName={initiative.name}
          handleClose={closeDeleteModal}
          handleSubmit={deleteInitiativeMutation.mutate}
        />
      )}
    </MenuDrivenContainer>
  );
}

export const InitiativePerformanceContent = ({
  initiative,
  portfolioId,
}: {
  initiative: ExtendedInitiative;
  portfolioId: string | undefined;
}) => (
  <ContentContainer>
    <SectionStatus initiative={initiative} />
    <SectionEpics initiative={initiative} />
    <SectionTeams initiative={initiative} portfolioId={portfolioId} />
  </ContentContainer>
);

interface TitleComponentProps {
  title: string;
  maxWidth?: string;
}

const TitleComponent = ({ title, maxWidth = '100%' }: TitleComponentProps) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      const current = titleRef.current;
      if (current) {
        setIsOverflowing(current.scrollWidth > current.clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [title]);

  return (
    <Tooltip label={isOverflowing ? title : ''} disabled={!isOverflowing} position="bottom" withArrow>
      <OverflowHeading ref={titleRef} maxWidth={maxWidth}>
        {title}
      </OverflowHeading>
    </Tooltip>
  );
};

const Actions = ({
  initiative,
  onEdit,
  onDelete,
}: {
  initiative: ExtendedInitiative;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const isExternalInitiative = initiative.source !== InitiativeSource.Bloomfilter;

  if (showExternalInitiatives && isExternalInitiative && initiative.source_url) {
    const sourceLabels = {
      [InitiativeSource.Jira]: 'Jira',
      [InitiativeSource.JDC]: 'Jira',
      [InitiativeSource.ADO]: 'ADO',
      [InitiativeSource.Github]: 'GitHub',
    };

    return (
      <Button
        variant="outline"
        onClick={() => window.open(initiative.source_url as string, '_blank', 'noopener, noreferrer')}
        style={{ minWidth: 'fit-content' }}
      >
        <Flex direction="row" align="center" gap={4}>
          <Text>Review Initiative in {sourceLabels[initiative.source as keyof typeof sourceLabels]}</Text>
          <img alt="icon" height={20} width={20} src={iconNavigate} />
        </Flex>
      </Button>
    );
  }

  return (
    <div style={{ display: 'flex', gap: '15px' }}>
      <StyledButton leftSection={<img src={icons.iconEdit} />} onClick={onEdit}>
        Edit
      </StyledButton>
      <StyledButton
        leftSection={<img src={icons.iconDelete} className="delete-icon" />}
        onClick={onDelete}
        variant="outline"
      >
        Delete
      </StyledButton>
    </div>
  );
};

const OverflowHeading = styled.h1<{ maxWidth: string }>`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  margin-bottom: 0;
`;

const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 92vw;
  box-sizing: border-box;
  margin-bottom: 8px;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectLabel = styled.div`
  color: #666;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
`;

const StyledSelect = styled(Select)`
  .mantine-Input-input {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    font-size: 15px;
    min-width: 120px;
  }
`;

const StyledButton = styled(Button)`
  display: inline-flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 36px;
  width: 120px;
`;

const ContentContainer = styled.div`
  display: flex;
  padding: 8px 16px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  min-width: 1150px;
`;

const Description = styled.div`
  color: ${newCOLORS.darkerGray};
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
`;
