enum Tab {
  Portfolios = 'portfolios',
  Teams = 'teams',
  Boards = 'boards',
  WorkPeriods = 'work_periods',
}

enum ViewType {
  Single = 'single',
  Comparison = 'comparison',
  Assessment = 'assessment',
}

type Entities = { [key: string]: string };

enum MetricType {
  Tasks = 'tasks',
  Points = 'points',
}

enum WorkPeriodType {
  Defined = 'defined',
  Custom = 'custom',
}

enum Mode {
  Sprint = 'Sprint',
  WorkPeriod = 'WorkPeriod',
}

export { MetricType, Mode, Tab, ViewType, WorkPeriodType };
export type { Entities };
