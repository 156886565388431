import { Select } from '@mantine/core';
import { Fragment, forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { usePortfolios } from '../../../api/portfolio-client/portfolio-client.hooks';
import { Portfolio } from '../../../api/portfolio-client/portfolio-client.type';
import { PortfolioContext } from '../../../contexts';
import { refactorDropdownData } from '../../../helpers';
import { StandardText } from '../../../styles/new-shared-styled-components/new-shared-styled-components';
import { ProcessContext } from '../context/process.context';
import { useProcessScope } from '../process.hooks';

export type PortfolioSelectRef = {
  getPortfolios: () => Portfolio[];
};

export type PortfolioSelectProps = {
  handlePortfolioSelected?: (portfolio: Portfolio) => void;
  hideLabel?: boolean;
};

export const PortfolioSelect = forwardRef<PortfolioSelectRef, PortfolioSelectProps>(
  ({ hideLabel, handlePortfolioSelected }, ref) => {
    const { portfolio: portfolioFromGlobalContext, setPortfolio } = useContext(PortfolioContext);
    const { portfolio, setScope } = useProcessScope();
    const { setStages } = useContext(ProcessContext);

    const { portfolios = [] } = usePortfolios({ staleTime: 1000 * 60 * 5 });

    useEffect(() => {
      const hasPortfolios = portfolios.length > 0;
      const isActivePortfolioInvalid = !portfolio || !portfolios.find((p) => p.id === portfolio.id);

      if (portfolioFromGlobalContext && !portfolio) {
        setScope({
          portfolio: portfolioFromGlobalContext,
        });
      } else if (hasPortfolios && isActivePortfolioInvalid) {
        setScope({
          portfolio: portfolios[0],
        });
      }
    }, [portfolio, portfolios, setScope, portfolioFromGlobalContext]);

    useImperativeHandle(ref, () => ({
      getPortfolios: () => {
        return portfolios || [];
      },
    }));

    const portfolioSelected = (newPortfolio: Portfolio) => {
      if (newPortfolio.id === portfolio?.id) {
        return;
      }

      setScope({
        portfolio: newPortfolio,
      });
      setStages([]);

      handlePortfolioSelected && handlePortfolioSelected(newPortfolio);
      // Switch the global context to the new portfolio
      setPortfolio(newPortfolio);
    };

    return (
      <Fragment>
        {!hideLabel && <StandardText style={{ marginLeft: 16, marginRight: 8 }}>Portfolio</StandardText>}
        <Select
          data={refactorDropdownData(portfolios)}
          value={portfolio?.id}
          onChange={(value) => {
            portfolioSelected(portfolios.find((portfolio) => portfolio.id == value) as Portfolio);
          }}
        />
      </Fragment>
    );
  }
);
