import { ComboboxItem, ComboboxItemGroup } from '@mantine/core';
import dayjs from 'dayjs';
import { ProjectsResponse } from '../../../api/projects-client/projects-client.type';
import { WorkPeriod } from '../../../api/work-periods-client/work-periods-client.type';
import { WorkPeriodType } from '../process-analysis.type';

/**
 * Generates an array of ComboboxItemGroup objects representing the board options.
 *
 * @param {ProjectsResponse[]} teams - The array of Team objects to generate the ComboboxItemGroup objects from.
 * @return {ComboboxItemGroup[]} An array of ComboboxItemGroup objects representing the board options.
 */
const getBoardOptions = (teams: ProjectsResponse[]): ComboboxItemGroup[] => {
  return teams.map((team, index) => ({
    group: `${index + 1}-${team.name}`,
    items: team.subprojects
      .filter((subproject) => subproject.id && subproject.name)
      .map((subproject) => ({
        value: subproject.id,
        label: subproject.name,
      })),
  }));
};

/**
 * Returns an array of ComboboxItem objects representing the work period type options.
 *
 * @return {ComboboxItem[]} An array of ComboboxItem objects with the values and labels for the work period type options.
 */
const getWorkPeriodTypeOptions = (): ComboboxItem[] => {
  return [
    { value: WorkPeriodType.Defined, label: 'Predefined Work Period' },
    { value: WorkPeriodType.Custom, label: 'Custom Work Period' },
  ];
};

/**
 * Returns an array of ComboboxItem objects representing the work period options.
 *
 * @param {WorkPeriod[] | undefined} data - The array of WorkPeriod objects to generate the ComboboxItem objects from.
 * @return {ComboboxItem[]} An array of ComboboxItem objects representing the work period options.
 */
const getWorkPeriodOptions = (data: WorkPeriod[] | undefined): ComboboxItem[] => {
  if (!data) {
    return [];
  }

  return data.map(({ id, name }) => ({ value: id, label: name }));
};

/**
 * Sorts an array of WorkPeriod objects by their start dates in descending order.
 *
 * @param {WorkPeriod[]} data - The array of WorkPeriod objects to sort.
 * @return {WorkPeriod[]} The sorted array of WorkPeriod objects.
 */
const sortWorkPeriods = (data: WorkPeriod[]): WorkPeriod[] => {
  return data.toSorted((a, b) => dayjs(b.start_date).diff(dayjs(a.start_date)));
};

export { getBoardOptions, getWorkPeriodOptions, getWorkPeriodTypeOptions, sortWorkPeriods };
