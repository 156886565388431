import { GlossaryContent } from './help.type';

export const glossaryContent: GlossaryContent[] = [
  {
    pageName: 'Portfolio',
    name: 'Active Sprints C or worse',
    description:
      'Sum of sprints in the portfolio where the current Sprint Performance Score is a C or worse indicating risk to meeting the sprint goal.',
  },
  {
    pageName: 'Current Sprint',
    name: 'AI Summary',
    description: 'Executive take away key insights to inform their data-driven decision-making.',
  },
  {
    pageName: 'Financials',
    name: 'Allocation of costs for Bloomfilter (all teams)',
    description: 'Allocation of costs for Bloomfilter (all teams).',
  },
  {
    pageName: 'Current Sprint',
    name: 'At Risk',
    description: 'Daily measure within the sprint if the work is at risk.',
  },
  {
    pageName: 'Financials',
    name: 'Average',
    description: 'Total dollars divided by number of months from the range selected.',
  },
  {
    pageName: 'Portfolio',
    name: 'Average Cycle Time',
    description: 'Average time, in days, between starting a task and completing the task, for all portfolio projects.',
  },
  {
    pageName: 'Portfolio',
    name: 'Average Work Item in Days',
    description:
      'Average number of days, since task creation, to either: task completion, if completed; or current day for all tasks in an active sprint, for all portfolio projects.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Background',
    description:
      'Leadership shares context for each sprint, so executives can quickly reference what was going on in that timeframe.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Blocked',
    description: 'Task held up.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Bloomcast',
    description:
      'A model that requires a minimum of six (6) two-week sprints. It will base predictions on the average heuristics of the sprint data if you are not using two-week sprints OR until the current two-week sprint is at least halfway complete.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Bulk Status Changes',
    description:
      'When large numbers of tasks are marked done in a single day, it indicates they are not being deployed often enough or are being bulk updated.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Burnt',
    description: 'Percentage of sprint elapsed.',
  },
  {
    pageName: 'Strategy',
    name: 'Business Value',
    description: 'Business value of a priority as measure by customer.',
  },
  {
    pageName: 'Strategy',
    name: 'Completed Tasks',
    description: 'Tasks completed over total tasks in a priority.',
  },
  {
    pageName: 'Strategy',
    name: 'Completed Tasks From Contributing Teams',
    description: 'Sum of tasks completed by each team that contributes to a given priority',
  },
  {
    pageName: 'Strategy',
    name: 'Completion',
    description: 'Amount of priority that has been completed.',
  },
  {
    pageName: 'Strategy',
    name: 'Completion %',
    description: 'Completed tasks out of total tasks from all Epics that contribute to this initiative.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Complexity',
    description:
      'Average estimate for pointed tasks. Points should describe the product’s team estimate of how complex an issue is, and give the team a sense of the uncertainty, labor, or skill needed to complete the card. Points are often confused with time estimates. Even when using a 1:1 point-to-days ratio, points are rarely a predictable indicator of how much a team can do, but rather how many challenging things they are planning to do in the cycle. The Fibonacci scale is the most frequently used point scale for complexity.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Consistent Commitments',
    description:
      'If total commitments vary between sprints, it usually indicates poor process adherence, scoping, and/or prioritization.',
  },
  {
    pageName: 'Strategy',
    name: 'Contributing Epics',
    description: 'List of epics that comprise a given priority.',
  },
  {
    pageName: 'Strategy',
    name: 'Contributing Since',
    description: 'Date of first task',
  },
  {
    pageName: 'Strategy',
    name: 'Contributing Teams',
    description: 'Teams contributing to a given priority.',
  },
  {
    pageName: 'Financials',
    name: 'Cost',
    description: 'Amount of money being spent per team per month.',
  },
  {
    pageName: 'Financials',
    name: 'Cost per point',
    description: 'Dollars spent per month divided by points completed.',
  },
  {
    pageName: 'Financials',
    name: 'Cost per task',
    description: 'Dollars spent per month divided by tasks completed.',
  },
  {
    pageName: 'Strategy',
    name: 'Cost to date',
    description:
      'Approximate dollar investment into a priority measure by total cost of teams contributing and percentage of effort towards that priority.',
  },
  {
    pageName: 'Financials',
    name: 'Costs vs output for Bloomfilter (all teams)',
    description: 'Cost vs output for Bloomfilter.',
  },
  {
    pageName: 'Project',
    name: 'Cycle Time',
    description: 'The time between starting a task and completing work.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Cycle Time Consistency',
    description:
      'Well-prepared teams execute with consistent cycle times. When cycle time varies it can indicate an issue with scoping or process adherence.',
  },
  {
    pageName: 'Financials',
    name: 'Daily Burn',
    description: 'Amount of money being spent per team per day.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Done',
    description: 'Work finished.',
  },
  {
    pageName: 'Strategy',
    name: 'End Date',
    description: 'End date of a priority.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Flow of work',
    description: 'Chart showing work by stage that grows with bottlenecks',
  },
  {
    pageName: 'Current Sprint',
    name: 'Flow of work, by phase',
    description: 'How each planned task is flowing through the phases of work in this sprint.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Flow of work, delivery confidence',
    description: "How work in this sprint is or isn't keeping up with needed pace, based on historical performance.",
  },
  {
    pageName: 'Current Sprint',
    name: 'Flow of work, on track to match pace',
    description: 'Is work on track to match pace?',
  },
  {
    pageName: 'Strategy',
    name: 'Focus',
    description: 'Percentage of effort by each team devoted to a given priority',
  },
  {
    pageName: 'Current Sprint',
    name: 'In Review',
    description: 'Task in code review.',
  },
  {
    pageName: 'Current Sprint',
    name: 'In Test',
    description: 'Acceptance.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Independence',
    description:
      'Tasks with zero dependency. Part of planning a sprint is understanding the sequence that issues need to be resolved in, and not just their priority. A frequently-used function in the system of record is to link two issue cards together when one is blocking or is blocked by the other. This alerts the team that they can’t start a particular card until work is done on the issue that blocks it.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Inter Sprint Continuity',
    description:
      'When points are removed rather than rolled over, it gives an incorrect picture of how the team is achieving their goals.',
  },
  {
    pageName: 'Project',
    name: 'Lead Time',
    description: 'The time between creating a task and completing work.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Missing Days',
    description:
      'Missing working days between sprints means that all work that happens on those days will not be tracked.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Multiple Point Changes Per Task',
    description:
      'When points change multiple times, it can be a sign of poor estimation, or right sizing a task effort by the team',
  },
  {
    pageName: 'Current Sprint',
    name: 'Off Track',
    description: 'Daily measure within the sprint if work is off track.',
  },
  {
    pageName: 'Current Sprint',
    name: 'On Track',
    description: 'Daily measure within the sprint if work is on track.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Plan in Motion',
    description:
      'Ensuring that all expectations are met by end of the sprint period requires procedural checks to make sure objectives are tracking well. When plan in motion % is too high towards the end of a sprint period that indicates those items are at risk of not being completed.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Plan Updates',
    description:
      'Changes to scope. An issue card really only has value if it has a description (spec or requirements) and a refined complexity point or time estimate. The complexity point or time estimate is refined when developers review the description, ask questions, and potentially break the issue out further, often to separate out work between the back and front end.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Points Acceptance Rate',
    description:
      'Projects consistently hitting below 60% of committed tasks is strong indicator that teams are not being setup for success.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Points Added Mid-Sprint',
    description:
      'When sprints add >70% of their initial scope mid-sprint, it is a strong indicator that the process is leaving the team unprepared.',
  },
  {
    pageName: 'Financials',
    name: 'Points Committed',
    description: 'Sum of points that are considered in scope for a particular sprint.',
  },
  {
    pageName: 'Portfolio',
    name: 'Portfolio Issues',
    description: 'Risks affecting projects in portfolio. Sum of issues across all projects.',
  },
  {
    pageName: 'Portfolio',
    name: 'Portfolio Performance Score',
    description:
      "The Portfolio performance score provides an overall assessment of projects, considering each project's Project Performance Score and the number of developers on that project.",
  },
  {
    pageName: 'Current Sprint',
    name: 'PR Closed (%)',
    description:
      'The amount of pull requests in a sprint that are opened then closed, but not merged. This usually means that the work done needs to be redone or never makes it to any user environment, signaling waste in your process.',
  },
  {
    pageName: 'Project',
    name: 'Product Delivery Score',
    description:
      'The Product Delivery Score is the average of the Sprint Performance Scores over a given time period (up to the last six sprints). It helps spot patterns and grading trends across sprints.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Progress',
    description:
      'See how current work is tracking and predict work that will need to carryover. Proprietary AI summary narrative.',
  },
  {
    pageName: 'Portfolio',
    name: 'Projects trending negatively',
    description:
      'Sum of projects in the portfolio where the average Sprint Performance Score is lower for the two most recently completed sprints when compared to the two sprints prior to the two most recent.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Quality',
    description:
      'Bug and tech debt share. This measure indicates how much of the sprint is made up of bug or technical debt tasks. There is no estimate of complexity associated with these cards, which makes quality critical to manage and ensure progress is happening that moves the product forward.',
  },
  {
    pageName: 'Project',
    name: 'Reaction Time',
    description: 'The time between creating a task and starting work.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Readiness',
    description:
      'Defined tasks. An issue card really only has value if it has a description (specification or requirements) and a refined complexity point or time estimate (it is refined when developers review the description, ask questions, and potentially break the issue out further). The issue isn’t ready if you’re just doing the minimum in the system of record (creating a new issue with just a title). Despite how commonsense this seems, it happens quite often, due to the hectic pace of a development cycle and the frequency of issue cards that spin out of conversation.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Ready',
    description: 'Task not started.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Review Time',
    description:
      'Average days PR is open. The average amount of time, in days, your developers’ pull requests remain open before they are merged into an upstream branch in your source control repositories. A "pull request" is a request from one developer to another developer to merge code into a branch of code. Branches are typically the code that is running in some environment, with a main branch of code that is running in production. Pull requests are used to review code before it gets merged.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Scope',
    description:
      'Tasks added or removed to plan. This measure indicates how many issues were added or removed from the sprint after it was planned. Starting a sprint in the system of record is a one-time thing. Once you start a sprint, you can’t pause or stop it. You can only pull things into and out of it. Note: It’s not totally uncommon to accidentally add an issue to an active sprint instead of putting in the backlog as the system of record user intended. In those moments, we can’t tell that it’s an accident.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Sprint Consistency',
    description:
      'Sprints are not consistently starting/stopping on same days of week. Teams operate better when they have a routine. Starting sprints on different days of the week introduces risk of poor sprint preparation.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Sprint Coverage',
    description:
      'Sprints do not cover same number of working days. Uneven sprints will give an incorrect view into actual team velocity.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Sprint Performance Score',
    description:
      'Current sprint performance score is based on performance, warnings, and issues. Identify how a project team is performing and tracking as compared to expectations for the sprint period. The Sprint Performance Score measures the daily difference between actual and ideal progress, considering the planned size of the spirit in points.',
  },
  {
    pageName: 'Project',
    name: 'Stale tasks',
    description:
      'Tasks in the backlog have not been updated in 120 days. This indicates that a task was important initially, but has been neglected in prioritization. This can be due to a shift in business requirements or poor acceptance criteria.',
  },
  {
    pageName: 'Strategy',
    name: 'Start Date',
    description: 'Start date of priority.',
  },
  {
    pageName: 'Strategy',
    name: 'Status',
    description:
      'An evaluative measure that weighs tasks done, team size, and historical pace against the end date of the initiative.',
  },
  {
    pageName: 'Current Sprint',
    name: 'Strategy',
    description:
      'Percentage tied to an Epic. The issue is mapped to an Epic. This does not happen by default. The system of record user must create the mapping. It is a conscious decision to map an issue to an Epic. Epics should only be used to describe features or capabilities that are significant strategic investments.',
  },
  {
    pageName: 'Financials',
    name: 'Task Committed',
    description: 'Tasks that are considered in scope for a particular sprint.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Tasks Acceptance Rate',
    description:
      'Projects consistently hitting below 60% of committed tasks is strong indicator that teams are not being setup for success.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Tasks Moving Backward',
    description:
      'Tasks added or removed to plan. This measure indicates how many issues were added or removed from the sprint after it was planned. Starting a sprint in the system of record is a one-time thing. Once you start a sprint, you can’t pause or stop it. You can only pull things into and out of it. Note: It’s not totally uncommon to accidentally add an issue to an active sprint instead of putting in the backlog as the system of record user intended. In those moments, we can’t tell that it’s an accident.',
  },
  {
    pageName: 'Strategy',
    name: 'Team',
    description: 'Names of teams contributing to priority.',
  },
  {
    pageName: 'Strategy',
    name: 'Team Performance Score',
    description:
      'The average of the Sprint Performance Scores over a given time period (up to the last six sprints). It helps spot patterns and grading trends across sprints.',
  },
  {
    pageName: 'Project',
    name: 'Technical Debt Tasks',
    description:
      "The amount of tasks that are labeled as 'Tech Debt'. When the amount of tech debt tasks exceeds this threshold, this can cause a slowdown in process or cycle time.",
  },
  {
    pageName: 'Financials',
    name: 'Throughput',
    description: 'Number of tasks completed.',
  },
  {
    pageName: 'Project',
    name: 'Throughput',
    description:
      'Throughput measures the number of tasks processed in each sprint. Assessing the classifications of work that is assigned to a team.',
  },
  {
    pageName: 'Strategy',
    name: 'Time elapsed',
    description: 'Initiative time elapsed.',
  },
  {
    pageName: 'Current Sprint',
    name: 'To deploy',
    description: 'Staged.',
  },
  {
    pageName: 'Financials',
    name: 'Total',
    description: 'Total dollars for the date range selected.',
  },
  {
    pageName: 'Portfolio',
    name: 'Total Throughput',
    description: 'Number of tasks completed.',
  },
  {
    pageName: 'Process Analysis',
    name: 'Untested Tasks',
    description:
      'Ideally, tasks will flow consistently through the appropriate statuses, ensuring predictable and quality work.',
  },
  {
    pageName: 'Project',
    name: 'Velocity',
    description:
      'Velocity measures the work (in points) a team completes during a sprint period. Assessing how fast a team is moving to complete the expected work ahead of them.',
  },
  {
    pageName: 'Current Sprint',
    name: 'WIP',
    description: 'Task started.',
  },
  {
    pageName: 'Project',
    name: 'Work Days Planned',
    description:
      'Backlog items ready for development in project. A healthy backlog is one that has enough tasks “ready for development” to fill an average sprint. Tasks that Bloomfilter considers “ready for development” have the following characteristics: In a backlog status and has a completed name, description, and point estimate. Not spikes or tech debt (research items for developers). In a product that uses sprints.',
  },
];
