import { useContext, useEffect } from 'react';
import { useAvailableMeasures } from '../../api/work-periods-client/work-periods-client.hooks';
import { WorkPeriodScope } from '../../api/work-periods-client/work-periods-client.type';
import { CrumbItem } from '../../components/breadcrumb/breadcrumb.type';
import { PortfolioContext, ProjectContext, SidebarContext } from '../../contexts';
import { useGlobalStore } from '../../store/global-store/global-store';
import { setAvailableMeasures } from '../../store/process-analysis-store/process-analysis-store.actions';
import { onPortfolioBreadcrumbClick } from '../side-bar/side-bar.helper';

/**
 * Custom hook for fetching a list of available measures for the process analysis page.
 * Stores the result in the process analysis store.
 *
 * @returns {{ isFetching: boolean }} - An object containing an isFetching flag.
 */
const useAvailableMeasuresData = (): { isFetching: boolean } => {
  const portfolio = useGlobalStore((state) => state.portfolio);

  const { data, query } = useAvailableMeasures(
    { scope: WorkPeriodScope.ProcessAnalysis, portfolio_id: portfolio?.id },
    { staleTime: Infinity }
  );

  useEffect(() => {
    if (data) {
      const measures = data[WorkPeriodScope.ProcessAnalysis]?.measures || [];

      setAvailableMeasures(measures);
    }
  }, [data]);

  return { isFetching: query.isFetching };
};

/**
 * Generates breadcrumbs based on the portfolio and project information.
 *
 * @return {CrumbItem[]} An array of breadcrumb items representing the navigation path.
 */
const useBreadcrumbs = (): CrumbItem[] => {
  const { portfolio } = useContext(PortfolioContext);
  const { setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);

  if (!portfolio?.organization?.name) {
    return [{ labelName: 'Process Analysis' }];
  }

  return [
    {
      labelName: portfolio.organization.name,
      href: '/application/dashboard',
      onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
    },
    { labelName: 'Process Analysis' },
  ];
};

export { useAvailableMeasuresData, useBreadcrumbs };
