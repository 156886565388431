import { getRequestToken } from '../../helpers/storage/storage';

/**
 * Creates an agent websocket connection
 * @param organization_id: the id of the organization to connect to
 * @returns {WebSocket} - a websocket connection
 */
function createAgentWebsocket(organization_id: string): WebSocket {
  let baseURL = import.meta.env.VITE_APP_API_URL + '/ws/agents';
  baseURL = baseURL.replace('http', 'ws').replace('https', 'wss');
  const url = baseURL + '/' + organization_id + `/?token=${getRequestToken()}`;
  return new WebSocket(url);
}

/**
 * Ask the agent a question via websocket
 * @param websocket: WebSocket - an active websocket connection
 * @param message: String - the string to send to the agent
 * @param context: String - the context of the agent (sprint, financials, etc)
 * @param thread_id: String - an active thread id if one exists
 * @param project_id: String - the project id if available
 * @param sprint_id: String - the sprint id if available
 * @returns {void}
 */
function askAgent(
  webSocket: WebSocket | undefined,
  message: string,
  context: string,
  thread_id: string,
  project_id: string,
  sprint_id: string
): void {
  webSocket?.send(
    JSON.stringify({
      message,
      type: context,
      thread_id,
      project_id,
      sprint_id,
    })
  );
}

export { askAgent, createAgentWebsocket };
