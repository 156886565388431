import { styled } from '@linaria/react';
import {
  ActiveElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { createWeekendBackgroundPlugin } from '../../../../helpers/chart-plugins/weekend-background-element';
import { round } from '../../../../helpers/math-helpers/math-helpers';
import { newCOLORS } from '../../../../styles/colors';
import { HeadingTag } from '../../../../styles/shared-styled-components';
import { Metric } from '../assessment-view.type';
import { DataPoint } from '../section-pace.types';

export type Props = {
  data: DataPoint[];
  labels: string[];
  metric: Metric;
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

export const FlowOfWork = ({ data, labels, metric }: Props) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: false,
    cubicInterpolationMode: 'monotone',
    borderWidth: 5,
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },
    onHover: (event: ChartEvent, elements: ActiveElement[], chart: ChartJS) => {
      let cursorStyle = 'default';

      const isOnElement = elements.length > 0;
      const isOnPlan = isOnElement && chart?.data.datasets[elements[0].datasetIndex].label === 'Actual Work';
      if (isOnPlan) {
        cursorStyle = 'pointer';
      }
      if (event?.native?.target) {
        (event.native.target as HTMLElement).style.cursor = cursorStyle;
      }
    },

    scales: {
      x: {
        stacked: true,
        title: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: metric === Metric.Tasks ? 'Tasks' : 'Points',
          font: {
            size: 16,
          },
        },
        afterFit: function (scaleInstance: any) {
          scaleInstance.width = scaleInstance.width + 40;
        },
        ticks: {
          font: {
            size: 14,
          },
        },
      },
    },

    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        labels: {
          boxWidth: 2,
          boxHeight: 12,
          pointStyle: 'rect',
        },
      },

      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<'line'>) => {
            const label = context.dataset.label || '';
            const value = context.dataset.data[context.dataIndex];
            const formattedValue = typeof value === 'number' ? round(value, 1) : null;

            return `${label}: ${formattedValue}`;
          },
        },
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
    },
  };

  const weekendOrHolidays = data.map((dataPoint) => dataPoint.weekend_or_holiday);

  const formattedData = {
    labels,
    datasets: [
      {
        label: 'Actual Work',
        order: 1,
        stack: 'Stack 0',
        data: data.map((dataPoint) => dataPoint.complete),
        hidden: false,
        borderColor: newCOLORS.magenta,
        backgroundColor: newCOLORS.magenta,
        pointStyle: 'circle',
        pointRadius: 4,
        pointHoverRadius: 5,
        borderWidth: 2,
      },
      {
        label: 'Plan',
        order: 2,
        stack: 'Stack 1',
        data: data.map((dataPoint) => dataPoint.ideal),
        hidden: false,
        borderColor: newCOLORS.blue,
        pointRadius: 4,
        pointStyle: 'circle',
        backgroundColor: newCOLORS.blue,
        borderWidth: 2,
      },
    ],
  };

  return (
    <ChartContainer>
      <CenterHeadingTag>Flow of work</CenterHeadingTag>
      <div style={{ height: '350px', display: 'flex' }}>
        <div style={{ width: '99%' }}>
          <Line options={options} data={formattedData} plugins={[createWeekendBackgroundPlugin(weekendOrHolidays)]} />
        </div>
      </div>
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  margin-bottom: 25px;
`;

const CenterHeadingTag = styled(HeadingTag)`
  text-align: center;
`;
