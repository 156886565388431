import { styled } from '@linaria/react';
import { useContext, useState } from 'react';
import { useIntegrationEntries } from '../../../api/integrations-client/integrations-client.hooks';
import { IntegrationEntry } from '../../../api/integrations-client/integrations-client.type';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../../components/page-header-message/page-header-message';
import { GLOBAL_MAILTO_SUPPORT_LINK } from '../../../constants';
import { PortfolioContext, ProjectContext, SidebarContext, UserContext } from '../../../contexts';
import { useOrganizationId, useRoles } from '../../../helpers/auth-helpers/auth.hooks';
import { useDocumentTitle } from '../../../helpers/general-helpers';
import { newCOLORS } from '../../../styles/colors';
import { BreadcrumbContainer } from '../../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../../menu-driven-container/menu-driven-container';
import { onPortfolioBreadcrumbClick } from '../../side-bar/side-bar.helper';
import { AvailableIntegrations } from './available-integrations';
import { Section } from './integrations-list.type';
import { UpcomingIntegrations } from './upcoming-integrations';

export function IntegrationsList() {
  useDocumentTitle('Integrations - Bloomfilter');
  const { user } = useContext(UserContext);
  const { portfolio } = useContext(PortfolioContext);
  const { setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);
  const { isAdmin } = useRoles();
  const organizationId = useOrganizationId();

  const [showRefreshMessage, setShowRefreshMessage] = useState<boolean>(false);
  const [openedSection, setOpenedSection] = useState<Section | null>(Section.AvailableIntegrations);

  const { data: integrationEntries = [], query } = useIntegrationEntries(organizationId, {
    enabled: !!organizationId,
    select: (data: IntegrationEntry[]) => data.filter((integration) => integration.projects.length > 0),
  });

  return (
    <MenuDrivenContainer containerStyles={{ position: 'relative' }}>
      <BrandedLoadingOverlay visible={query.isFetching} transitionDuration={30} variant="colored" />
      <PageContainer style={{ paddingLeft: 16 }}>
        {portfolio?.organization && (
          <BreadcrumbContainer style={{ paddingBottom: 0, height: 40 }}>
            <Breadcrumb
              crumbItems={[
                {
                  labelName: portfolio.organization?.name || 'Portfolio',
                  href: '/application/dashboard',
                  onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
                },
                { labelName: 'Integrations' },
              ]}
            />
          </BreadcrumbContainer>
        )}
        <HeadingContainer>
          <Heading>Integrations</Heading>
          <ContactLink href={GLOBAL_MAILTO_SUPPORT_LINK}>Contact Customer Success</ContactLink>
        </HeadingContainer>
        {isAdmin && showRefreshMessage && <RefreshMessage onClose={() => setShowRefreshMessage(false)} />}
        {portfolio && user && !isAdmin && <NonAdminMessage />}
        <AvailableIntegrations
          integrationEntries={integrationEntries}
          isLoading={query.isFetching}
          openedSection={openedSection}
          setOpenedSection={setOpenedSection}
          setShowRefreshMessage={setShowRefreshMessage}
        />
        <UpcomingIntegrations openedSection={openedSection} setOpenedSection={setOpenedSection} />
      </PageContainer>
    </MenuDrivenContainer>
  );
}

const RefreshMessage = ({ onClose }: { onClose: () => void }) => {
  const supportLink = (
    <a href={GLOBAL_MAILTO_SUPPORT_LINK}>
      <LinkText>Customer Success</LinkText>
    </a>
  );

  const message = (
    <>Data refresh successfully initiated. Check back at this page for updates, or contact {supportLink}.</>
  );

  return <PageHeaderMessage message={message} color="green" onClose={onClose} />;
};

const NonAdminMessage = () => {
  const { admins } = useRoles();

  const adminName = admins[0]?.name;

  const defaultMessage = 'You are not listed as an admin on this account';

  const supportLink = (
    <a href={GLOBAL_MAILTO_SUPPORT_LINK}>
      <LinkText>Customer Success</LinkText>
    </a>
  );

  const getMessage = () => {
    if (adminName) {
      return (
        <>
          {defaultMessage}, for help please contact {adminName} or {supportLink} at Bloomfilter.
        </>
      );
    }

    return (
      <>
        {defaultMessage}, for help please contact {supportLink} at Bloomfilter.
      </>
    );
  };

  return (
    <div>
      <PageHeaderMessage message={getMessage()} color="yellow" />
    </div>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled.h1`
  color: #444;
  font-family: Figtree;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ContactLink = styled.a`
  color: #5c3ecf;
  text-align: right;
  font-family: Figtree;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  height: fit-content;
  margin-right: 58px;
`;

const LinkText = styled.span`
  color: ${newCOLORS.indigo};
  cursor: pointer;
  text-decoration: underline;
`;
