import { QueryObserverResult, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { ProcessAnalysisMeasureComparisonData } from '../board-client/board-client.type';
import {
  createPortfolio,
  deletePortfolio,
  fetchPortfolios,
  getInitiativeCompletion,
  getInitiativeFocus,
  getPortfolioMeasures,
  updatePortfolio,
} from './portfolio-client';
import { Portfolio, ProcessAnalysisChartData } from './portfolio-client.type';

/**
 * Query manager for fetching a list of portfolios for the current user.
 *
 * @param options UseQueryOptions
 * @returns a object of portfolios along with the query manager
 */
const usePortfolios = (
  options?: UseQueryOptions<Portfolio[]>
): {
  portfolios: Portfolio[];
  query: QueryObserverResult<Portfolio[]>;
} => {
  const query = useQuery({
    queryKey: ['portfolios'],
    queryFn: fetchPortfolios,
    ...options,
  });

  let portfolios: Portfolio[] = [];
  if (Array.isArray(query.data) && query.data.length > 0) {
    portfolios = query.data.sort((a, b) => a.name.localeCompare(b.name));
  }

  return { portfolios: portfolios || [], query };
};

/**
 * Query manager for creating a portfolio.
 *
 * @returns Portfolio
 */
const useCreatePortfolio = () => {
  return useMutation({
    mutationFn: createPortfolio,
  });
};

const useUpdatePortfolio = () => {
  return useMutation({
    mutationFn: updatePortfolio,
  });
};

const useDeletePortfolio = () => {
  return useMutation({
    mutationFn: deletePortfolio,
  });
};

/**
 * Query manager for fetching a list of initiative completions for the given portfolio
 *
 * @param portfolioId portfolio id to fetch initiative completions for
 * @param startDate start date to filter the initiative completions
 * @param endDate end date to filter the initiative completions
 * @param options UseQueryOptions
 * @returns a object of initiative completions along with the query manager
 */
const useInitiativeCompletions = (
  portfolioId: string,
  startDate: string,
  endDate: string,
  options?: UseQueryOptions<ProcessAnalysisChartData>
): {
  initiativeCompletions: ProcessAnalysisChartData | undefined;
  query: QueryObserverResult<ProcessAnalysisChartData>;
} => {
  const query = useQuery({
    queryKey: ['initiativeCompletions', portfolioId, startDate, endDate],
    queryFn: () => getInitiativeCompletion(portfolioId, startDate, endDate),
    ...options,
  });

  return { initiativeCompletions: query.data, query };
};

/**
 * Query manager for fetching a list of initiative focus for the given portfolio
 *
 * @param portfolioId portfolio id to fetch initiative focus for
 * @param startDate start date to filter the initiative focus
 * @param endDate end date to filter the initiative focus
 * @param options UseQueryOptions
 * @returns a object of initiative focus along with the query manager
 */
const useInitiativeFocus = (
  portfolioId: string,
  startDate: string,
  endDate: string,
  options?: UseQueryOptions<ProcessAnalysisChartData>
): {
  initiativeFocus: ProcessAnalysisChartData | undefined;
  query: QueryObserverResult<ProcessAnalysisChartData>;
} => {
  const query = useQuery({
    queryKey: ['initiativeFocus', portfolioId, startDate, endDate],
    queryFn: () => getInitiativeFocus(portfolioId, startDate, endDate),
    ...options,
  });

  return { initiativeFocus: query.data, query };
};

/**
 * Query manager for fetching a list of measures for the given portfolio
 *
 * @param portfolioId portfolio id to fetch measures for
 * @param startDate start date to filter the measures for
 * @param endDate end date to filter the measures for
 * @param options UseQueryOptions
 * @returns a object of measures along with the query manager
 */
const usePortfolioMeasures = (
  portfolioId: string,
  startDate: string,
  endDate: string,
  options?: UseQueryOptions<ProcessAnalysisMeasureComparisonData>
): {
  portfolioMeasures: ProcessAnalysisMeasureComparisonData | undefined;
  query: QueryObserverResult<ProcessAnalysisMeasureComparisonData>;
} => {
  const query = useQuery({
    queryKey: ['portfolioMeasures', portfolioId, startDate, endDate],
    queryFn: () => getPortfolioMeasures(portfolioId, startDate, endDate),
    ...options,
  });

  return { portfolioMeasures: query.data, query };
};

export {
  useCreatePortfolio,
  useDeletePortfolio,
  useInitiativeCompletions,
  useInitiativeFocus,
  usePortfolioMeasures,
  usePortfolios,
  useUpdatePortfolio,
};
